import React, { useRef } from "react";
import { Box, Button, Icon, LinearProgress } from "@mui/material";

export default function SelectFile({
    handleClickUpload,
    progress,
    accept = "*"
}) {
    const inputRef = useRef(null);
    const handleChange = (e) => {
        const files = e.target.files;
        handleClickUpload(files);
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    return (
        <React.Fragment>
            <input
                type="file"
                onChange={handleChange}
                ref={inputRef}
                style={{ display: "none" }}
                accept={accept}
            />
            {progress && (
                <Box my={1}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
            )}
            <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Icon>add</Icon>}
                onClick={handleClick}
            >
                Upload File
            </Button>
        </React.Fragment>
    );
}
