import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../mapSnapshot";

export default async function loadNotes(userId) {
    try {
        const collectionRef = collection(db, "notes");
        const q = query(collectionRef, where("userId", "==", userId));
        const notesSnapshot = await getDocs(q);
        const notes = mapSnapshot(notesSnapshot);
        return notes;
    } catch (error) {
        console.log(error);
    }
}
