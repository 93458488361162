import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import convertDate from "../utils-functions/convertDate";
import getDayMonthYear from "../utils-functions/getDayMonthYear";

export default function getSalesRanking(
    sales,
    month,
    year,
    type = ["project", "subsale"],
    status = ["BOOKED", "BILLED", "TO-INVOICE"],
    filterDateBy = "bookingDate",
    projects = []
) {
    const getComm = (sale) => {
        const project = projects.find((doc) => doc.name === sale.project);
        if (project) {
            let renComm = 0;
            project.commRate.forEach((rate) => {
                if (convertDate(sale.bookingDate > convertDate(rate.date))) renComm = rate.ren;
            });
            if (!renComm) renComm = 1;
            const comm = (parseFloat(sale.netPricePortion) * parseFloat(renComm)) / 100;
            return comm;
        } else {
            let renComm = 1;
            const comm = (parseFloat(sale.netPricePortion) * parseFloat(renComm)) / 100;
            return comm;
        }
    };

    if (!sales) return { salesRankingArrayByMonth: [], salesRankingArrayByYear: [] };
    let salesRankingByMonth = {};
    let commRankingByMonth = {};
    let salesRankingByYear = {};
    let commRankingByYear = {};
    let salesRankingArrayByMonth = [];
    let commRankingArrayByMonth = [];
    let salesRankingArrayByYear = [];
    let commRankingArrayByYear = [];
    let salesInMonth = [];
    let salesInYear = [];
    sales.forEach((sale) => {
        if (!sale[filterDateBy]) return;
        let m = getDayMonthYear(convertDate(sale[filterDateBy])).month;
        let y = getDayMonthYear(convertDate(sale[filterDateBy])).year;
        if (parseInt(m) === month && parseInt(y) === year && toShowType(sale, type) && toShowStatus(sale, status)) {
            salesInMonth.push(sale);
        }
        if (parseInt(y) === year && toShowType(sale, type) && toShowStatus(sale, status)) {
            salesInYear.push(sale);
        }
    });

    salesInMonth.forEach((sale) => {
        salesRankingByMonth[sale.agent] = salesRankingByMonth[sale.agent]
            ? salesRankingByMonth[sale.agent] + parseFloat(sale.netPricePortion)
            : parseFloat(sale.netPricePortion);

        commRankingByMonth[sale.agent] = commRankingByMonth[sale.agent]
            ? commRankingByMonth[sale.agent] + getComm(sale)
            : getComm(sale);
    });

    salesInYear.forEach((sale) => {
        salesRankingByYear[sale.agent] = salesRankingByYear[sale.agent]
            ? salesRankingByYear[sale.agent] + parseFloat(sale.netPricePortion)
            : parseFloat(sale.netPricePortion);

        commRankingByYear[sale.agent] = commRankingByYear[sale.agent]
            ? commRankingByYear[sale.agent] + getComm(sale)
            : getComm(sale);
    });

    Object.keys(salesRankingByMonth).map((key) => {
        salesRankingArrayByMonth.push({
            name: key,
            netPrice: salesRankingByMonth[key]
        });
    });

    Object.keys(commRankingByMonth).map((key) => {
        commRankingArrayByMonth.push({
            name: key,
            netPrice: commRankingByMonth[key]
        });
    });

    Object.keys(salesRankingByYear).map((key) => {
        salesRankingArrayByYear.push({
            name: key,
            netPrice: salesRankingByYear[key]
        });
    });

    Object.keys(commRankingByYear).map((key) => {
        commRankingArrayByYear.push({
            name: key,
            netPrice: commRankingByYear[key]
        });
    });

    salesRankingArrayByMonth.sort((a, b) => {
        return b.netPrice - a.netPrice;
    });

    commRankingArrayByMonth.sort((a, b) => {
        return b.netPrice - a.netPrice;
    });

    salesRankingArrayByYear.sort((a, b) => {
        return b.netPrice - a.netPrice;
    });

    commRankingArrayByYear.sort((a, b) => {
        return b.netPrice - a.netPrice;
    });

    return {
        salesRankingArrayByMonth,
        salesRankingArrayByYear,
        commRankingArrayByMonth,
        commRankingArrayByYear
    };
}

export function getTotalSales(salesRankingArray) {
    if (!salesRankingArray) return;
    let totalSales = 0;
    salesRankingArray.forEach((s) => {
        totalSales += s.netPrice;
    });
    return totalSales;
}

function toShowStatus(sale, status) {
    let show = false;
    if (arrayIsEmpty(status)) return;
    status.forEach((s) => {
        if (sale.status === s) show = true;
    });
    return show;
}

function toShowType(sale, type) {
    let show = false;
    type.forEach((t) => {
        if (sale.type === t) show = true;
    });
    return show;
}
