import { Container } from "@mui/system";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SalesChartComponent } from "../components/SalesChartComponent";
import { AuthContext } from "../context/AuthContext";
import loadIncomes from "../functions/loadIncomes";
import {
    GridContainer,
    GridDivider,
    GridFlexBox,
    Loading,
    Name,
    Title
} from "../themes/themes";
import getDayMonthYear from "../utils-functions/getDayMonthYear";

const DATE = new Date();
const YEAR = parseInt(getDayMonthYear(DATE).year);

export default function IncomesChartPage() {
    const [incomes, setIncomes] = useState();
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            let incomes = await loadIncomes(user, YEAR - 1);
            incomes = mapIncomes(incomes);
            console.log(incomes);
            setIncomes(incomes);
            setLoading(false);
        };
        if (!incomes) getData();
    }, []);

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Title>Income Chart</Title>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridFlexBox>
                    <Name>YEAR {YEAR - 1}</Name>
                </GridFlexBox>
                <GridDivider ptb={10} />
                {incomes &&
                    incomes.map((income, i) => (
                        <SalesChartComponent
                            key={i}
                            i={i}
                            sale={income}
                            salesRanking={incomes}
                        />
                    ))}
            </GridContainer>
        </Container>
    );
}

function mapIncomes(incomes) {
    const array = [];
    incomes.forEach((income) => {
        array.push({ ...income, name: income.agent, netPrice: income.amount });
    });
    return array;
}
