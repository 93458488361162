import { IconButton } from "@mui/material";
import { useContext } from "react";
import { ListContext } from "../context/ListContext";
import { IconC, Name } from "../themes/themes";

export const UserSelectList = ({ selectedUser, users, handleSelect }) => {
    const { openGlobalList } = useContext(ListContext);

    const handleClick = async () => {
        const response = await openGlobalList(
            users,
            "Select member",
            "agent",
            "face",
            false,
            true
        );
        if (response) {
            handleSelect(response);
        }
    };
    return (
        <>
            <Name>{selectedUser && selectedUser.agent}</Name>
            <IconButton onClick={handleClick}>
                <IconC>face</IconC>
            </IconButton>
        </>
    );
};
