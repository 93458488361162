import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { HOMEPAGE_ITEMS } from "../../settings/settings";
import { GridContainer, GridDivider, GridFlexBox, Name } from "../../themes/themes";

export default function HomePage() {
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    return (
        <Box m={1}>
            <GridContainer>
                <GridFlexBox mtb2>
                    <Typography variant="h5">{user ? `Welcome ${user.displayName}` : "Homepage"}</Typography>
                </GridFlexBox>
                <GridDivider />
                {HOMEPAGE_ITEMS.map((item, i) => (
                    <React.Fragment key={i}>
                        {item.description === "divider" ? (
                            <GridDivider />
                        ) : (
                            <GridFlexBox>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: item.color, width: "300px" }}
                                    onClick={() => navigate(item.link)}
                                >
                                    {item.title}
                                </Button>
                            </GridFlexBox>
                        )}
                    </React.Fragment>
                ))}
                <GridDivider />
            </GridContainer>
        </Box>
    );
}
