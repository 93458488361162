import { Button } from "@mui/material";
import { useContext } from "react";
import { ListContext } from "../context/ListContext";
import { UsersContext } from "../context/UsersContext";
import populateInvolveInSelectedUser from "../seeding-functions/populateInvolveInSelectedUser";
import { GridContainer, GridFlexBox, Title } from "../themes/themes";

export function PopulateInvolve() {
    const { users } = useContext(UsersContext);

    const { openGlobalList } = useContext(ListContext);

    const handleClick = async () => {
        const response = await openGlobalList(
            users,
            "Select user",
            "agent",
            "face",
            false,
            true
        );
        if (response) {
            console.log(response);
            await populateInvolveInSelectedUser(users, [response]);
        }
    };
    return (
        <GridContainer>
            <GridFlexBox>
                <Title>Populate Involve</Title>
            </GridFlexBox>
            <GridFlexBox>
                <Button variant="contained" onClick={handleClick}>
                    Select User to Populate
                </Button>
            </GridFlexBox>
        </GridContainer>
    );
}
