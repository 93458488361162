import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useState } from "react";
import { useEffect } from "react";
import loadRecentSales from "./loadRecentSales";
import { useNavigate } from "react-router-dom";
import convertToLowerCase from "../../utils-functions/convertToLowerCase";
import { Box, Container, LinearProgress } from "@mui/material";
import SalesTopBar from "../../components/SalesTopBar";
import { GridContainer, GridDivider, GridFlexBox, Name } from "../../themes/themes";
import SaleDetails from "../../components/SaleDetails";
import FullDialog from "../../components/FullDialog";
import loadUser from "../../functions/loadUser";

const numberPerPage = 50;

export default function RecentDealsPage() {
    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState("all");
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [sales, setSales] = useState([]);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const sales = await loadRecentSales();
            setSales(sales);
            setLoading(false);
        };
        getData();
    }, []);

    const navigate = useNavigate();

    const saveSettings = (settings) => {
        const windowScrollY = window.scrollY;
        let salesPageMemory = { tabValue, search, page, windowScrollY };
        salesPageMemory = { ...salesPageMemory, ...settings };
        localStorage.setItem("salesPageMemory", JSON.stringify(salesPageMemory));
    };

    const handleOpen = async (sale) => {
        saveSettings({});
        console.log(sale);
        navigate(`/sale-card-page/${sale.id}`);
    };

    const handleSearch = (value) => {
        setSearch(value);
        setPage(1);
        window.scrollTo(0, 0);
        saveSettings({ search: value, page: 1 });
    };

    const handleSetTabValue = (value) => {
        setTabValue(value);
        setPage(1);
        window.scrollTo(0, 0);
    };

    const handleClickFace = (email) => {
        setEmail(email);
        setOpen(true);
    };

    let filteredSales = sales;

    let totalPage = 1;
    if (filteredSales) {
        switch (tabValue) {
            case "all":
                break;
            default:
                filteredSales = sales.filter((sale) => sale.type === tabValue);
        }
        filteredSales = filteredSales.filter((sale) => {
            const { agent, project, unitNo, buyerName, branch } = sale;
            const searchField =
                convertToLowerCase(agent) +
                convertToLowerCase(project) +
                convertToLowerCase(unitNo) +
                convertToLowerCase(buyerName) +
                convertToLowerCase(branch);
            return searchField.includes(search.toLowerCase());
        });
        totalPage = Math.ceil(filteredSales.length / numberPerPage);
        if (totalPage === 0) totalPage = 1;
    }

    let unitIdArray = [];

    const UserDetails = () => {
        const [fUser, setfUser] = useState();

        useEffect(() => {
            const loadData = async () => {
                const fUser = await loadUser(email);
                setfUser(fUser);
            };
            loadData();
        }, [email]);

        return (
            <Container maxWidth="sm">
                <GridContainer>
                    <GridFlexBox w="30%" fs>
                        <Name>Name:</Name>
                    </GridFlexBox>
                    <GridFlexBox w="70%" fs>
                        <Name>{fUser?.agent}</Name>
                    </GridFlexBox>
                    <GridFlexBox w="30%" fs>
                        <Name>Phone:</Name>
                    </GridFlexBox>
                    <GridFlexBox w="70%" fs>
                        <Name>{fUser?.tel}</Name>
                    </GridFlexBox>
                </GridContainer>
            </Container>
        );
    };

    return (
        <Box m={1}>
            <Container component="main" maxWidth="sm" disableGutters>
                <SalesTopBar
                    setSearch={setSearch}
                    handleSetTabValue={handleSetTabValue}
                    tabValue={tabValue}
                    page={page}
                    setPage={setPage}
                    totalPage={totalPage}
                    search={search}
                    handleSearch={handleSearch}
                    totalItem={filteredSales ? filteredSales.length : 0}
                />
                <GridContainer>
                    {filteredSales &&
                        filteredSales
                            .slice(page * numberPerPage - numberPerPage, page * numberPerPage)
                            .map((sale, i) => {
                                let existed = checkSaleId(unitIdArray, sale.unitId);
                                unitIdArray.push(sale.unitId);
                                return (
                                    <SaleDetails
                                        existed={existed}
                                        handleOpen={handleOpen}
                                        sale={sale}
                                        handleClickFace={() => handleClickFace(sale.agentEmail)}
                                        key={i}
                                    />
                                );
                            })}
                    <GridDivider ptb={10} />
                </GridContainer>
                {loading && <LinearProgress />}
            </Container>
            <FullDialog Component={<UserDetails />} open={open} title="User" handleClose={() => setOpen(false)} />
        </Box>
    );
}

const checkSaleId = (unitIdArray, unitId) => {
    if (unitIdArray.includes(unitId) && unitIdArray[unitIdArray.length - 1] === unitId) {
        return true;
    } else return false;
};
