import { Container } from "@mui/system";
import { useState } from "react";
import {
    ButtonC,
    GridContainer,
    GridFlexBox,
    InputC,
    Name
} from "../themes/themes";

export default function CustomDate({ handleClose }) {
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Name>Start date</Name>
                    <InputC
                        type={"date"}
                        value={start}
                        onChange={(e) => {
                            setStart(e.target.value);
                        }}
                    ></InputC>
                </GridFlexBox>
                <GridFlexBox>
                    <Name>End date</Name>
                    <InputC
                        type={"date"}
                        value={end}
                        onChange={(e) => {
                            setEnd(e.target.value);
                        }}
                    ></InputC>
                </GridFlexBox>
                <GridFlexBox>
                    <ButtonC onClick={() => handleClose({ start, end })}>
                        Set
                    </ButtonC>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
