import { Box } from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { GridFlexBox, Name } from "../themes/themes";
import formatNumber from "../utils-functions/formatNumber";

export function SalesChartComponent({ i, sale, salesRanking }) {
    let barLength = 1;
    if (salesRanking[0].netPrice) {
        barLength = (sale.netPrice / salesRanking[0].netPrice) * 100;
        if (!barLength) barLength = 1;
    }

    return (
        <React.Fragment key={i}>
            <GridFlexBox fe m0 p0 w="8%">
                <Name ct fs10>
                    {i + 1}
                </Name>
            </GridFlexBox>
            <GridFlexBox fs m0 p0 w="30%">
                <Name nowrap ct>
                    {sale.name}
                </Name>
            </GridFlexBox>
            <GridFlexBox fe m0 p0 w="20%">
                <Name ct>{formatNumber(sale.netPrice, 0)}</Name>
            </GridFlexBox>
            <GridFlexBox fs gap={0} m0 p0 w="42%">
                <Box
                    width={`${barLength}%`}
                    sx={{ backgroundColor: blue[500] }}
                    marginTop={"1px"}
                    marginBottom={"1px"}
                    marginRight={"8px"}
                    height={"10px"}
                />
            </GridFlexBox>
        </React.Fragment>
    );
}
