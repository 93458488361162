import getDayMonthYear from "../utils-functions/getDayMonthYear";
import loadSales from "./loadSales";
import loadUsers from "./loadUsers";

const initializeData = async (users, setUsers, sales, setSales, user, setLoading, yearsLoaded) => {
    try {
        if (!users) {
            setLoading(true);
            const users = await loadUsers(user);
            setLoading(false);
            setUsers(users);
        }

        if (!sales) {
            const date = new Date();
            const year = getDayMonthYear(date).year;
            setLoading(true);
            const sales = await loadSales(user, year, year, yearsLoaded);
            setLoading(false);
            setSales(sales);
        }
    } catch (error) {
        console.log(error);
    }
};

export default initializeData;
