import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import loadUser from "./loadUser";

export default async function signUp(
    firstName,
    lastName,
    email,
    password,
    setUser,
    setAuthUser
) {
    email = email.toLowerCase();

    try {
        const userInFs = await loadUser(email);
        if (!userInFs) {
            notification(
                "Opps",
                `We cannot find ${email} in the system. Please use the email in Atlas to Sign Up`,
                "warning"
            );
            console.log("Cannot find email ", email);
            return null;
        }

        const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        );
        const user = userCredential.user;

        setAuthUser(user);
        await createNewUser(firstName, lastName, email, setUser);
        console.log(user, " is created");
        notification("Success", `Signed up ${email} successfully`, "success");
        return true;
    } catch (err) {
        console.log(err);
        let message = err.message;

        if (err.code === "auth/email-already-in-use") {
            message = "This email is already in use. Please use another email";
        }
        notification("Error", message, "warning");
        return null;
    }
}

const createNewUser = async (firstName, lastName, email, setUser) => {
    try {
        const userRef = doc(db, "users", email);
        const userData = {
            id: email,
            email: email,
            firstName: firstName,
            lastName: lastName,
            displayName: firstName + " " + lastName
        };
        await setDoc(userRef, userData, { merge: true });
        const user = await loadUser(email);
        setUser(user);
        console.log("Firebase user created", firstName);
    } catch (err) {
        console.log(err);
    }
};
