import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Icon, ListItemIcon, ListItemText } from "@mui/material";

export default function MenuDropdown({ handleClose, menuDropDown, anchorEl, user }) {
    const getMenu = (item, i) => {
        if (item.action === "Divider") {
            return <Divider key={i} />;
        }

        if (!item.role) {
            return (
                <MenuItem onClick={handleClose} data-index={item.action} key={i}>
                    <ListItemIcon>
                        <Icon fontSize="small">{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={item.action} />
                </MenuItem>
            );
        }

        if (user) {
            if (user.role === item.role) {
                return (
                    <MenuItem onClick={handleClose} data-index={item.action} key={i}>
                        <ListItemIcon>
                            <Icon fontSize="small">{item.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={item.action} />
                    </MenuItem>
                );
            }
        }
    };

    return (
        <div>
            <Menu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {menuDropDown.map((item, i) => getMenu(item, i))}
            </Menu>
        </div>
    );
}
