import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default function seedIncomes(data, users, year = 2022) {
    try {
        data.forEach(async (d) => {
            let income;
            const foundUser = users.filter((user) => user.agent === d.agent);
            if (foundUser.length === 1) {
                income = {
                    agent: d.agent,
                    email: foundUser[0].email,
                    amount: d.amount,
                    year: year,
                    involve: foundUser[0].involve,
                    involveId: foundUser[0].involveId
                };
            } else if (foundUser.length > 1) {
                const found = users.find((a) => a.email === d.email);
                if (found) {
                    income = {
                        agent: d.agent,
                        email: found.email,
                        amount: d.amount,
                        year: year,
                        involve: found.involve,
                        involveId: found.involveId
                    };
                } else {
                    console.log("Cannot find ", d.email);
                }
            }
            const id = income.email + "-" + year;
            const docRef = doc(db, "incomes", id);
            await setDoc(docRef, income, { merge: true });
            console.log(id, " updated in Firebase");
        });
    } catch (err) {
        console.log(err);
    }
}
