import { getApp, initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

//Main Database
const firebaseConfig = {
    apiKey: "AIzaSyBQXJiEA02xQq_vJXjky6eWXcPubK94T8g",
    authDomain: "eliteone-app-v3.firebaseapp.com",
    projectId: "eliteone-app-v3",
    storageBucket: "eliteone-app-v3.appspot.com",
    messagingSenderId: "871534364525",
    appId: "1:871534364525:web:60d6202a7ec3a0930d3c64",
    measurementId: "G-S55CVG8JZF"
};

let db;
let auth;
let storage;
let app;

if (window.location.hostname === "localhost1") {
    app = initializeApp(firebaseConfig);
    db = getFirestore();
    auth = getAuth();
    storage = getStorage();
    const functions = getFunctions(getApp());
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, "localhost", 8080);
    console.log("Running Firebase Emulator");
} else {
    app = initializeApp(firebaseConfig);
    db = getFirestore(app);
    auth = getAuth(app);
    storage = getStorage(app);
}

export { db, auth, storage };

export default app;
