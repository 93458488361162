import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { notification } from "../utils-functions/notification";
import sendSignInLink from "../functions/sendSignInLink";
import signInWithEmail from "../functions/signInWithEmail";
import {
    GridContainer,
    GridDivider,
    GridFlexBox,
    InputC,
    Loading,
    Name,
    Title
} from "../themes/themes";

export default function SignInWithEmail() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmation, setConfirmation] = useState();
    const { emailParam } = useParams();
    const { setUser, setAuthUser } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        console.log(emailParam);
        let email = window.localStorage.getItem("emailForSignIn");
        if (email) setEmail(email);
    }, []);

    const handleClick = async () => {
        if (emailParam) {
            setLoading(true);
            const user = await signInWithEmail(email, setUser, setAuthUser);
            setLoading(false);
            if (user) {
                navigate("/");
            } else {
                notification(
                    "Opps",
                    "You do not have an Account with the System. Please check with your admin",
                    "warning"
                );
            }
        } else {
            setLoading(true);
            const status = await sendSignInLink(email);
            setLoading(false);
            if (status) {
                setConfirmation("Please check your email for the sign in link");
            } else {
                setConfirmation("Something went wrong. Try refresh the page");
            }
        }
    };

    return (
        <Container maxWidth={"sm"}>
            <GridContainer s2>
                <GridFlexBox>
                    <Title>Sign In with Email</Title>
                </GridFlexBox>
                <GridDivider />
                <Loading loading={loading} />
                <GridFlexBox>
                    <Name>Please enter your email address</Name>
                </GridFlexBox>
                <GridFlexBox>
                    <InputC
                        fullWidth
                        size="small"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email..."
                    />
                </GridFlexBox>
                <GridFlexBox>
                    <Name>{confirmation && confirmation}</Name>
                </GridFlexBox>
                <GridFlexBox>
                    <Button
                        variant="contained"
                        onClick={handleClick}
                        disabled={confirmation ? true : false}
                    >
                        {emailParam ? "Sign in" : "Send sign in Link to Email"}
                    </Button>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
