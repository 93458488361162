import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import convertDate from "../utils-functions/convertDate";

export default function getSalesRankingByDate(
    sales,
    start,
    end,
    type = ["project", "subsale"],
    status = ["BOOKED", "BILLED", "TO-INVOICE"],
    filterDateBy = "bookingDate",
    projects = []
) {
    const getComm = (sale) => {
        const project = projects.find((doc) => doc.name === sale.project);
        if (project) {
            let renComm = 0;
            project.commRate.forEach((rate) => {
                if (convertDate(sale.bookingDate > convertDate(rate.date)))
                    renComm = rate.ren;
            });
            const comm =
                (parseFloat(sale.netPricePortion) * parseFloat(renComm)) / 100;
            return comm;
        } else {
            return 0;
        }
    };

    if (!sales) return [];
    let salesWithinDates = [];
    let salesRanking = {};
    let commRanking = {};
    let salesRankingArray = [];
    let commRankingArray = [];
    sales.forEach((sale) => {
        if (!sale[filterDateBy]) return;
        let startDate = new Date(start);
        let endDate = new Date(end);
        if (
            convertDate(sale[filterDateBy]) >= startDate &&
            convertDate(sale[filterDateBy]) <= endDate &&
            toShowStatus(sale, status) &&
            toShowType(sale, type)
        ) {
            salesWithinDates.push(sale);
        }
    });

    salesWithinDates.forEach((sale) => {
        salesRanking[sale.agent] = salesRanking[sale.agent]
            ? salesRanking[sale.agent] + parseFloat(sale.netPricePortion)
            : parseFloat(sale.netPricePortion);

        commRanking[sale.agent] = commRanking[sale.agent]
            ? commRanking[sale.agent] + getComm(sale)
            : getComm(sale);
    });

    Object.keys(salesRanking).map((key) => {
        salesRankingArray.push({
            name: key,
            netPrice: salesRanking[key]
        });
    });

    Object.keys(commRanking).map((key) => {
        commRankingArray.push({
            name: key,
            netPrice: commRanking[key]
        });
    });

    salesRankingArray.sort((a, b) => {
        return b.netPrice - a.netPrice;
    });

    commRankingArray.sort((a, b) => {
        return b.netPrice - a.netPrice;
    });

    return { salesRankingArray, commRankingArray };
}

export function getTotalSales(salesRankingArray) {
    if (!salesRankingArray) return;
    let totalSales = 0;
    salesRankingArray.forEach((s) => {
        totalSales += s.netPrice;
    });
    return totalSales;
}

function toShowStatus(sale, status) {
    let show = false;
    if (arrayIsEmpty(status)) return;
    status.forEach((s) => {
        if (sale.status === s) show = true;
    });
    return show;
}

function toShowType(sale, type) {
    let show = false;
    type.forEach((t) => {
        if (sale.type === t) show = true;
    });
    return show;
}
