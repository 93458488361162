import { notification } from "../utils-functions/notification";
import {
    getAuth,
    isSignInWithEmailLink,
    signInWithEmailLink
} from "firebase/auth";
import loadUser from "./loadUser";

export default async function signInWithEmail(email, setUser, setAuthUser) {
    try {
        const auth = getAuth();

        if (isSignInWithEmailLink(auth, window.location.href)) {
            if (!email) {
                email = window.prompt(
                    "Please provide your email for confirmation"
                );
            }
            const result = await signInWithEmailLink(
                auth,
                email,
                window.location.href
            );

            window.localStorage.removeItem("emailForSignIn");
            const authUser = result.user;

            const user = await loadUser(authUser.email);
            if (user) {
                setUser(user);
                setAuthUser(authUser);
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("authUser", JSON.stringify(authUser));
                console.log(user.email);
                notification("Signed in", "Signed in successfully", "success");
                return user;
            } else {
                return null;
            }
        }
    } catch (error) {
        console.log(error);

        if (error.code === "auth/invalid-action-code") {
            notification(
                "Opps",
                "Invalid sign in link or expired link. Please regenerate again.",
                "danger"
            );
        } else {
            notification("Opps", error.message, "danger");
        }
    }
}
