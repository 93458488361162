import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default function fixGary(users) {
    users.forEach(async (user) => {
        if (user.directLeader === "Gary Tan") {
            const docRef = doc(db, "users", user.id);
            await setDoc(
                docRef,
                { ...user, directLeader: "Gary" },
                { merge: true }
            );
            console.log("Fixed Gary");
        }
    });
}
