import { useContext } from "react";
import { useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { uploadFile } from "../functions/uploadFile";
import { v4 as uuidv4 } from "uuid";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Icon,
    LinearProgress,
    Typography
} from "@mui/material";
import SelectFiles from "./SelectFilesComponent";
import isFileImage from "../utils-functions/isFileImage";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import { Container } from "@mui/system";

export default function UploadDocument({ handleFile }) {
    const [progress, setProgress] = useState();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);

    const handleClickSelect = (selectedFiles) => {
        console.log(selectedFiles);
        const newFiles = [...files, ...selectedFiles];
        setFiles(newFiles);
        console.log(newFiles);
    };

    const handleClickUpload = async () => {
        if (arrayIsEmpty(files)) {
            return;
        }
        setLoading(true);

        const folderRef = `notes/${user.email}/${uuidv4()}`;
        const filesRef = await Promise.all(
            files.map(async (selectedFile) => {
                const fileRef = folderRef + "/" + selectedFile.name;
                const downloadURL = await uploadFile(
                    selectedFile,
                    fileRef,
                    setProgress
                );
                console.log(downloadURL);

                const reference = {
                    name: selectedFile.name,
                    folderRef: folderRef,
                    type: selectedFile.type,
                    downloadURL: downloadURL
                };

                setLoading(false);
                setProgress(null);

                return reference;
            })
        );

        console.log(filesRef);
        handleFile(folderRef);
    };

    const handleDelete = (i) => {
        console.log(i);
        const newFiles = [...files];
        newFiles.splice(i, 1);
        setFiles(newFiles);
    };

    return (
        <Container maxWidth={"md"}>
            <Box m={1} alignItems="center">
                <Grid container justify="center">
                    {loading && <LinearProgress />}
                    {files.map((file, i) => (
                        <Card
                            style={{ width: "100%", marginTop: "3px" }}
                            key={i}
                        >
                            <CardContent style={{ padding: 0 }}>
                                <Grid item container>
                                    <Grid item xs={5}>
                                        <Box
                                            display={"flex"}
                                            justifyContent="center"
                                            alignItems={"center"}
                                        >
                                            {isFileImage(file) ? (
                                                <img
                                                    width={"100%"}
                                                    src={URL.createObjectURL(
                                                        file
                                                    )}
                                                    alt=""
                                                />
                                            ) : (
                                                <Icon
                                                    fontSize="large"
                                                    color="primary"
                                                >
                                                    article
                                                </Icon>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            display={"flex"}
                                            justifyContent="center"
                                            alignItems={"center"}
                                        >
                                            <Typography
                                                variant="body2"
                                                style={{ marginLeft: "5px" }}
                                            >
                                                <a
                                                    href={file.downloadURL}
                                                    target="blank"
                                                >
                                                    {file.name}
                                                </a>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Icon
                                            fontSize="small"
                                            style={{
                                                color: "darkred",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => handleDelete(i)}
                                        >
                                            delete
                                        </Icon>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                    <Grid item xs={12} align="center">
                        <SelectFiles
                            handleClickSelect={handleClickSelect}
                            progress={progress}
                        />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Button
                            variant="contained"
                            color="warning"
                            style={{ marginTop: "5px" }}
                            onClick={handleClickUpload}
                            disabled={arrayIsEmpty(files)}
                        >
                            Upload Files
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
