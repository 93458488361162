import { Box, Button, Divider, Grid, styled, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import formatNumber from "../utils-functions/formatNumber";
import { GridFlexBox } from "../themes/themes";
import PaginationComponent from "./PaginationComponent";
import SearchComponent from "./SearchComponent";

export default function SalesTopBar({
    tabValue,
    handleSetTabValue,
    page,
    totalPage,
    setPage,
    search,
    handleSearch,
    totalItem
}) {
    const TabButton = styled(Button)(({ id }) => {
        let color = blue[500];
        if (id === tabValue) color = "orange";
        return {
            color: color
        };
    });

    return (
        <Box display={"flex"} justifyContent="center" alignItems={"center"}>
            <Box sx={{ width: "100vw" }} pb={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent={"center"} alignItems="center">
                            <TabButton id="all" onClick={() => handleSetTabValue("all")}>
                                All
                            </TabButton>
                            <TabButton id="project" onClick={() => handleSetTabValue("project")}>
                                Projects
                            </TabButton>
                            <TabButton id="subsale" onClick={() => handleSetTabValue("subsale")}>
                                Subsales
                            </TabButton>
                            <TabButton id="rental" onClick={() => handleSetTabValue("rental")}>
                                Rental
                            </TabButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display={"flex"} alignItems={"center"} justifyContent="center">
                            <SearchComponent handleSearch={handleSearch} search={search} />
                        </Box>
                    </Grid>
                    <GridFlexBox>
                        <PaginationComponent page={page} totalPage={totalPage} setPage={setPage} />
                        <Typography variant="body2">Total record: {formatNumber(totalItem, 0)}</Typography>
                    </GridFlexBox>
                </Grid>
            </Box>
        </Box>
    );
}
