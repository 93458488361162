import React from "react";
import { GridDivider, GridFlexBox, IconC, Name } from "../themes/themes";

export default function MemberDetails({ user, checkUser }) {
    return (
        <React.Fragment>
            <GridFlexBox xs={12} fs>
                <IconC
                    onClick={() => {
                        checkUser(user);
                    }}
                />
                <Name>{`${user.agent} (${user.fullName})`}</Name>
            </GridFlexBox>
            <GridFlexBox xs={12} fs>
                <IconC>email</IconC>
                <Name>{user.email}</Name>
            </GridFlexBox>
            <GridFlexBox xs={12} fs>
                <IconC>phone</IconC>
                <Name>{user.tel}</Name>
            </GridFlexBox>
            <GridFlexBox xs={12} fs>
                <IconC>account_tree</IconC>
                <Name nowrap={false}>{user.hierarchy}</Name>
            </GridFlexBox>
            <GridDivider />
        </React.Fragment>
    );
}
