import { Button } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import React, { useState } from "react";
import loadSales from "../functions/loadSales";
import { Status } from "../pages/SalesChartPage";
import { ButtonC, GridDivider, GridFlexBox, IconC, Name, Title } from "../themes/themes";
import formatDate from "../utils-functions/formatDate";
import formatNumber from "../utils-functions/formatNumber";
import CustomDate from "./CustomDate";
import FullDialog from "./FullDialog";

export function SalesHeaderComponent({
    checkStatus,
    user,
    checkType,
    totalSales,
    chartType,
    setChartType,
    setLoading,
    sales,
    setSales,
    yearsLoaded,
    type,
    setType,
    month,
    setMonth,
    year,
    setYear,
    display,
    setDisplay,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    status,
    setStatus
}) {
    const [openCustomDate, setOpenCustomDate] = useState(false);

    const handleChangeType = () => {
        if (chartType === "Sales") {
            setChartType("Commissions");
            setType(["project"]);
        } else {
            setChartType("Sales");
        }
    };

    const handleForward = () => {
        setDisplay("month");
        if (month === 12) {
            setMonth(1);
            setYear(year + 1);
            return;
        }
        setMonth(month + 1);
    };

    const clearCustomDates = () => {
        setStartDate();
        setEndDate();
    };

    const handleBack = async () => {
        setDisplay("month");
        if (month === 1) {
            setMonth(12);
            const newYear = year - 1;
            setYear(newYear);
            setLoading(true);
            const sales2 = await loadSales(user, newYear, newYear, yearsLoaded);
            if (sales2) {
                const newSales = [...sales, ...sales2];
                setSales(newSales);
            }

            setLoading(false);
            return;
        }
        setMonth(month - 1);
    };

    const handleChangeDisplay = () => {
        setStartDate();
        setEndDate();
        if (display === "month") {
            setDisplay("year");
        } else setDisplay("month");
    };

    const handleCustomDate = async () => {
        setOpenCustomDate(true);
    };

    const setCustomDate = (response) => {
        setOpenCustomDate(false);
        if (response) {
            setStartDate(response.start);
            setEndDate(response.end);
        }
    };

    const handleClickStatus = (value) => {
        console.log(value);
        switch (value) {
            case "booked":
                updateState(["BOOKED"], setStatus, status);
                break;
            case "converted":
                updateState(["BILLED", "TO-INVOICE"], setStatus, status);
                break;
            case "cancelled":
                updateState(["CANCELLED"], setStatus, status);
                break;
            default:
                return;
        }
    };

    const handleClickType = (value) => {
        switch (value) {
            case "project":
                updateState(["project"], setType, type);
                break;
            case "subsale":
                updateState(["subsale"], setType, type);
                break;
            case "rental":
                updateState(["rental"], setType, type);
                break;
            default:
                return;
        }
    };

    function updateState(s, setStatusFunction, state) {
        let newState = [...state];

        s.forEach((a) => {
            if (state.includes(a)) {
                let index = newState.findIndex((b) => b === a);
                newState.splice(index, 1);
            } else {
                newState = [...newState, a];
            }
        });

        setStatusFunction(newState);
    }

    const displayCancelled = () => {
        if (user.role === "Super Admin" || user.role === "Leader") return true;
    };

    return (
        <React.Fragment>
            <GridFlexBox>
                <Name>Total net value: </Name>
                <Name>{formatNumber(totalSales, 0)}</Name>
            </GridFlexBox>
            <GridDivider ptb={10} />
            <GridFlexBox>
                <Title>{chartType} Chart</Title>
                {checkChartType(chartType) && (
                    <ButtonC small p0 fs10 onClick={handleChangeType} ml1>
                        {chartType === "Sales" ? "to Commission" : "to Sales"}
                    </ButtonC>
                )}
            </GridFlexBox>
            <GridFlexBox p0>
                <Name fs12>{formatDate(new Date())}</Name>
            </GridFlexBox>
            <GridFlexBox>
                {!startDate ? (
                    <>
                        <IconC onClick={handleBack} cp>
                            arrow_back
                        </IconC>
                        {display === "month" && <Name>{month}</Name>}
                        {display === "month" && <Name>{" | "}</Name>}
                        <Name>{year}</Name>
                        <IconC onClick={handleForward} cp>
                            arrow_forward
                        </IconC>
                    </>
                ) : (
                    <>
                        <Name wrap fs12>
                            {startDate}
                        </Name>
                        <Name wrap fs12>
                            {endDate}
                        </Name>
                        <IconC onClick={clearCustomDates}>clear</IconC>
                    </>
                )}
                <Button size="small" onClick={handleChangeDisplay}>
                    {display === "month" ? "By Year" : "By Month"}
                </Button>
                <Button size="small" onClick={handleCustomDate}>
                    Custom Date
                </Button>
            </GridFlexBox>
            <GridFlexBox xs={12} md={6}>
                <Status bgcolor={blue[700]} clicked={checkStatus("booked")} onClick={() => handleClickStatus("booked")}>
                    Booked
                </Status>
                <Status
                    bgcolor={green[600]}
                    clicked={checkStatus("converted")}
                    onClick={() => handleClickStatus("converted")}
                >
                    Converted
                </Status>
                {displayCancelled() && (
                    <Status
                        bgcolor={red[600]}
                        clicked={checkStatus("cancelled")}
                        onClick={() => handleClickStatus("cancelled")}
                    >
                        Cancelled
                    </Status>
                )}
            </GridFlexBox>
            <GridFlexBox xs={12} md={6}>
                <Status clicked={checkType("project")} onClick={() => handleClickType("project")}>
                    Projects
                </Status>
                {chartType === "Sales" && (
                    <>
                        <Status clicked={checkType("subsale")} onClick={() => handleClickType("subsale")}>
                            Subsales
                        </Status>
                        <Status clicked={checkType("rental")} onClick={() => handleClickType("rental")}>
                            Rental
                        </Status>
                    </>
                )}
            </GridFlexBox>

            <FullDialog
                open={openCustomDate}
                handleClose={setCustomDate}
                title={"Set Custom Date"}
                Component={<CustomDate handleClose={setCustomDate} />}
            />
        </React.Fragment>
    );
}

const checkChartType = (chartType) => {
    if (chartType === "Sales" || chartType === "Commissions") return true;
    return false;
};
