import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function saveNote(note) {
    try {
        const collectionRef = collection(db, "notes");
        if (note.id) {
            const docRef = doc(db, "notes", note.id);
            await updateDoc(docRef, { ...note, updated: new Date() }, { merge: true });
            console.log("Firebase updated note");
            return note.id;
        } else {
            const response = await addDoc(collectionRef, { ...note, date: new Date() });
            console.log("Note created in Firebase");
            return response.id;
        }
    } catch (err) {
        console.log(err);
        return false;
    }
}
