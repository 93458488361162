import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { removeDuplicate } from "../utils-functions/removeDuplicate";
import { missingAgentsList } from "./missingAgents";

let missingAgents = [];

export default async function uploadAgentsData(data, users) {
    const start = 0;
    const end = data.length;

    try {
        await Promise.all(
            data.slice(start, end).map(async (user) => {
                const found = users.find((member) => member.email === user.email);

                if (found) {
                    if (!found.involveId.includes("daveckw@gmail.com")) {
                        console.log(found.agent, " missing leader.");
                    } else {
                        return;
                    }
                }

                const involveObj = getInvolve(user, users);
                const { involve } = involveObj;
                const { involveId } = involveObj;
                const hierarchy = involve2String(involve);
                user = {
                    ...user,
                    involve,
                    involveId,
                    numberOfInvolve: 0,
                    hierarchy: hierarchy
                };

                const userRef = doc(db, "users", user.email.toLowerCase());
                await setDoc(userRef, user, { merge: true });
                console.log(user.email, " updated in Firebase");
            })
        );

        missingAgents = removeDuplicate(missingAgents);
        console.log("missing Agents:", missingAgents);
    } catch (err) {
        console.log(err);
    }
}

function getInvolve(user, users) {
    let involve = [user.agent];
    let involveId = [user.email];

    const findLeader = (user) => {
        let foundUser = users.find((doc) => doc.email === user.directLeaderEmail);

        if (user.directLeaderEmail === "amychan1268@gmail.com.deleted") {
            foundUser = users.find((doc) => doc.email === "amychan1268@gmail.com");
        }

        if (foundUser) {
            try {
                involve.push(foundUser.agent);
                involveId.push(foundUser.email.toLowerCase());
                findLeader(foundUser);
            } catch (err) {
                console.log(err);
                console.log(foundUser);
                console.log(user);
            }
        } else {
            if (user.directLeaderEmail === "asproperland@gmail.com") return;

            const leaderEmail = missingAgentsList[user.agent];
            if (leaderEmail) {
                const leader = users.find((doc) => doc.email === leaderEmail);
                involve.push(leader.agent);
                involveId.push(leader.email.toLowerCase());
                findLeader(leader);
                return;
            } else {
                console.log("Cannot find user's leader ", user.agent);
                console.log(involve);
                missingAgents.push(user.agent);
            }
        }
    };
    findLeader(user);
    return { involve, involveId };
}

function involve2String(involve) {
    let hierarchy = involve[0];
    involve.forEach((doc, i) => {
        if (i === 0) return;
        hierarchy = hierarchy + " -> " + doc;
    });
    return hierarchy;
}
