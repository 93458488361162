import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function sendEmailVerificationLink(user) {
    try {
        const collectionRef = collection(db, "emailVerifications");
        await addDoc(collectionRef, {
            ...user,
            loginUrl: window.location.href
        });
        console.log("Email verification added to Firebase");
        return true;
    } catch (error) {
        console.log(error);
        notification(
            "Error",
            "Somthing went wrong! Please try again",
            "danger"
        );
        return false;
    }
}
