import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function loadUsers(user) {
    try {
        const usersRef = collection(db, "users");
        let q;
        if (user.role === "Super Admin" || user.role === "Admin") {
            q = query(usersRef, orderBy("numberOfInvolve", "desc"));
        } else {
            q = query(
                usersRef,
                where("involveId", "array-contains", user.id),
                orderBy("numberOfInvolve", "desc")
            );
        }
        const usersSnap = await getDocs(q);

        if (!usersSnap.empty) {
            const users = usersSnap.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id
            }));
            console.log(users);
            return users;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err);
    }
}
