import { Box, Button, Grid, Icon, LinearProgress } from "@mui/material";
import React from "react";
import { useRef } from "react";

export default function SelectFiles({ handleClickSelect, progress }) {
    const inputRef = useRef(null);
    const handleChange = (e) => {
        const files = e.target.files;
        handleClickSelect(files);
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    return (
        <React.Fragment>
            <input
                type="file"
                onChange={handleChange}
                ref={inputRef}
                style={{ display: "none" }}
                multiple
            />
            {progress && (
                <Grid item xs={12}>
                    <Box my={1}>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                        />
                    </Box>
                </Grid>
            )}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<Icon>add</Icon>}
                    onClick={handleClick}
                    style={{ marginTop: "5px" }}
                >
                    Select Files
                </Button>
            </Grid>
        </React.Fragment>
    );
}
