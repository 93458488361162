import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import ChatComponent from "../Playground/ChatComponent";
import chatGPT from "../Playground/chatGPT";
import ReactMarkdownComponent from "../../components/ReactMarkdown/ReactMarkdownComponent";

export default function QAPage() {
    const [summary, setSummary] = useState("");

    const handleSave = async (messages) => {
        console.log(messages);
        let context = "";
        messages.forEach((message) => {
            if (message.role === "user") {
                context += `user: ${message.content}\n`;
            } else if (message.role === "assistant") {
                context += `assistant: ${message.content}\n`;
            }
        });
        context += "Generate a summary of the conversation. Write it as marketing script for the project";
        await chatGPT(context, null, [], "gpt-3.5-turbo", setSummary);
    };

    return (
        <Container maxWidth="none" disableGutters>
            <GridContainer>
                <GridFlexBox>
                    <Title>Q&A Generator</Title>
                </GridFlexBox>
                <GridDivider />
                <Grid item display="flex" xs={12} md={4}>
                    <Box sx={{ height: "80vh", margin: "8px", padding: "8px" }}>
                        <ChatComponent handleSave={handleSave} />
                    </Box>
                </Grid>
                <Grid item display="flex" xs={12} md={4}>
                    <Box
                        sx={{
                            border: "1px solid lightgrey",
                            height: "80vh",
                            margin: "8px",
                            width: "100%",
                            padding: "8px"
                        }}
                    >
                        <ReactMarkdownComponent markdownText={summary} />
                    </Box>
                </Grid>
                <GridFlexBox
                    xs={12}
                    md={4}
                    sx={{ border: "1px solid lightgrey", height: "80vh", margin: "8px" }}
                ></GridFlexBox>
            </GridContainer>
        </Container>
    );
}
