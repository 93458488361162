import { Container } from "@mui/system";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { ProjectsContext } from "../context/ProjectsContext";
import { SalesContext } from "../context/SalesContext";
import getProjects from "../functions/getProjects";
import loadSales from "../functions/loadSales";
import updateProjects from "../functions/updateProjects";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, Loading, Name, TextFieldC, Title } from "../themes/themes";
import convertDate from "../utils-functions/convertDate";
import formatDate from "../utils-functions/formatDate";
import { Button, TextField } from "@mui/material";

export default function ProjectsPage() {
    const { sales, setSales } = useContext(SalesContext);
    const { user } = useContext(AuthContext);
    const { projects, setProjects } = useContext(ProjectsContext);

    const [loading, setLoading] = useState(false);
    const [showAll, setShowAll] = useState(true);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const sales = await loadSales(user);
            setSales(sales);
            const projects = await getProjects(sales);
            setProjects(projects);
            setLoading(false);
        };
        if (!sales) getData();
    }, []);

    const handleChange = (e, name, j) => {
        const { value } = e.target;
        const index = projects.findIndex((project) => project.name === name);
        setProjects((projects) => {
            projects[index].commRate[j]["ren"] = value;
            projects[index]["updated"] = true;
            return [...projects];
        });
    };

    const handleUpdate = async () => {
        setLoading(true);
        await updateProjects(projects);
        setLoading(false);
    };

    const handleChangeDeveloper = (e, project) => {
        const { value } = e.target;
        const index = projects.findIndex((p) => p.name === project.name);
        setProjects((projects) => {
            projects[index].developer = value;
            projects[index]["updated"] = true;
            return [...projects];
        });
    };

    const displayProjects = useMemo(() => {
        if (projects) {
            if (showAll) return projects;
            return projects.filter((project) => !project.commRate[0].ren);
        } else {
            return [];
        }
    }, [showAll]);

    return (
        <Container maxWidth="lg">
            <GridContainer>
                <GridFlexBox>
                    <Title>Projects</Title>
                    <ButtonC onClick={handleUpdate}>Update</ButtonC>
                </GridFlexBox>
                <GridFlexBox>
                    <Button onClick={() => setShowAll(!showAll)}>Show Projects with no comm</Button>
                    <Name>{showAll}</Name>
                </GridFlexBox>
                <Loading loading={loading} />
                {displayProjects?.map((project) => (
                    <React.Fragment key={project.name}>
                        <GridFlexBox fs w="35%">
                            <Name onClick={() => console.log(project)} ct>
                                {project.name}
                            </Name>
                        </GridFlexBox>
                        <GridFlexBox fs w="10%">
                            <Name ct>{project.count}</Name>
                        </GridFlexBox>
                        <GridFlexBox fs w="30%" fd="column">
                            {project.commRate.map((rate, j) => (
                                <GridContainer key={j}>
                                    <GridFlexBox fs w="50%">
                                        <Name ct>{formatDate(convertDate(rate.date))}</Name>
                                    </GridFlexBox>
                                    <GridFlexBox fs w="50%">
                                        <TextFieldC
                                            placeholder="Commission"
                                            size="small"
                                            onChange={(e) => handleChange(e, project.name, j)}
                                            type="number"
                                            value={rate.ren}
                                        />
                                    </GridFlexBox>
                                </GridContainer>
                            ))}
                        </GridFlexBox>
                        <GridFlexBox w="25%">
                            <TextField
                                size="small"
                                label="Developer"
                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                onChange={(e) => handleChangeDeveloper(e, project)}
                                value={project.developer || ""}
                            />
                        </GridFlexBox>
                        <GridDivider />
                    </React.Fragment>
                ))}
            </GridContainer>
        </Container>
    );
}
