import equal from "deep-equal";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import convertDate from "../utils-functions/convertDate";
import removeSpace from "../utils-functions/removeSpace";

export default async function seedSales(sales, users, existingSales = [], setMessages) {
    const start = 0;
    const end = sales.length;

    const salesInExcel = [];
    const newSales = [];
    let duplicated = {};
    let incompleteSales = [];

    try {
        const Promises = sales.slice(start, end).map(async (sale) => {
            if (!sale.unitId) return;
            sale = setType(sale);
            let id = setId(sale);

            const involveObj = getInvolve(sale, users);
            let involve = involveObj.involve;
            let involveId = involveObj.involveId;

            //Special Case
            if (sale.agent === "Jack Yeo") {
                let user = users.find((doc) => doc.agent === "Jack Yeo");
                if (user) {
                    involve = user.involve;
                    involveId = user.involveId;
                } else {
                    console.log("Cannot find Jack Yeo");
                }
            }
            if (!involveId.includes("daveckw@gmail.com")) {
                incompleteSales.push(sale);
                console.log(involve);
            }
            const f = salesInExcel.find((saleId) => saleId === id);
            salesInExcel.push(id);
            if (f) {
                duplicated = { [id]: duplicated[id] ? duplicated[id] + 1 : 2 };
                id = id + "-" + duplicated[id];
            }
            id = removeSpace(id);

            sale = { ...sale, involve, involveId, id };
            let existingSale = existingSales.find((eS) => eS.id === sale.id);
            if (!existingSale) {
                newSales.push(sale);
            } else {
                existingSale = convertExistingSale(existingSale);
                if (equal(sale, existingSale)) {
                    return Promise.resolve();
                }
            }

            const docRef = doc(db, "sales", id);
            const ref = await setDoc(docRef, sale, { merge: true });
            console.log(id, " updated in Firebase");
            const message = id + " updated in Firebase";
            logMessage(message, setMessages);
            return ref;
        });
        await Promise.all(Promises);
        console.log("New sales added:");
        logMessage("New sales added:", setMessages);
        console.log(newSales);
        logMessage(newSales.length, setMessages);
        console.log(salesInExcel.length);
        logMessage(salesInExcel.length, setMessages);
        console.log("Incompleted Sales");
        logMessage("Incompleted Sales", setMessages);
        console.log(incompleteSales);
        logMessage(incompleteSales.length, setMessages);
    } catch (err) {
        console.log(err);
    }
}

function convertExistingSale(existingSale) {
    let { agentJoinedDate, agentLastUpdate, bookingDate, spaSignedDate, signedLOReceivedDate, ...others } =
        existingSale;
    existingSale = {
        ...others,
        agentJoinedDate: convertDate(agentJoinedDate),
        bookingDate: convertDate(bookingDate),
        spaSignedDate: convertDate(spaSignedDate),
        agentLastUpdate: convertDate(agentLastUpdate),
        signedLOReceivedDate: convertDate(signedLOReceivedDate)
    };
    return existingSale;
}

function setId(sale) {
    return sale.unitId.toString() + "-" + sale.agentEmail + "-" + sale.agentPercentage;
}

function setType(sale) {
    if (!sale.project) {
        sale = { ...sale, type: "subsale" };
    } else {
        sale = { ...sale, type: "project" };
    }
    if (parseFloat(sale.spaPrice) < 50000) {
        sale = { ...sale, type: "rental" };
    }
    return sale;
}

function getInvolve(sale, users) {
    let involve = [];
    let involveId = [];

    const user = users.find((doc) => doc.email === sale.agentEmail);
    if (user) {
        involve = user.involve;
        involveId = user.involveId;
    } else {
        console.log("Cannot find user ", sale.agent, sale.agentEmail);
    }

    return { involve, involveId };
}

const logMessage = (message, setMessages) => {
    setMessages((prev) => (!arrayIsEmpty(prev) ? [...prev, message] : [message]));
};
