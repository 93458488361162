import React, { useContext, useState } from "react";
import { Container } from "@mui/system";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, GridItem, Loading, Title } from "../themes/themes";
import { AuthContext } from "../context/AuthContext";
import { Icon } from "@mui/material";
import { UsersContext } from "../context/UsersContext";
import { useEffect } from "react";
import { StateContext } from "../context/StateContext";
import addState from "../utils-functions/addState";
import { useNavigate, useParams } from "react-router-dom";
import DataFields from "../components/DataFields";
import loadUsersRT from "../functions/loadUsersRT";
import { ListContext } from "../context/ListContext";
import { SalesContext } from "../context/SalesContext";
import loadUser from "../functions/loadUser";

export default function UserCardPage() {
    const { user, setUser } = useContext(AuthContext);
    const { users, setUsers, setUnsubscribeUsers } = useContext(UsersContext);
    const { sales, setSales, setYearsLoaded } = useContext(SalesContext);
    const { openGlobalList } = useContext(ListContext);
    const { state, setState } = useContext(StateContext);

    const [signInAs, setSignInAs] = useState();
    const [displayUser, setDisplayUser] = useState();
    const [loading, setLoading] = useState(false);

    const { param } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const loadUserData = async () => {
            const fUser = await loadUser(param);
            setDisplayUser(fUser);
        };

        if (!users) loadData();

        if (user.role === "Super Admin") {
            addState("authUser", user, state, setState);
        }

        if (param) {
            if (users) {
                const fUser = users.find((user) => user.id === param);
                if (fUser) {
                    setDisplayUser(fUser);
                } else {
                    if (user.role === "Loan Consultant") {
                        loadUserData();
                    }
                }
            }
        } else {
            setDisplayUser(user);
        }
    }, [users]);

    const loadData = () => {
        loadUsersRT(user, setUsers, setUnsubscribeUsers, setLoading);
    };

    const handleClick = async () => {
        const response = await openGlobalList(users, "Sign in as", "displayName", "face", false, true);
        if (response) {
            setDisplayUser(response);
            setSignInAs(response);
            setUser(response);
            setUsers();
            setSales();
            setYearsLoaded([]);
            navigate("/");
        }
    };

    const handleUpdate = (object, key, value) => {
        if (object.id === user.id) {
            const newUser = { ...user, [key]: value };
            setUser(newUser);
        }
    };

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Title>User Card</Title>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridDivider />
                <GridFlexBox w="10%" fs>
                    <Icon fontSize="large">face</Icon>
                </GridFlexBox>
                <GridItem w="90%" fs fs20>
                    {displayUser && displayUser.displayName}
                </GridItem>
                <GridDivider />
                <DataFields object={displayUser} collectionId={"users"} handleUpdate={handleUpdate}></DataFields>
                <SignInAsMember state={state} signInAs={signInAs} handleClick={handleClick}></SignInAsMember>
                <GridFlexBox fs w="50%">
                    <ButtonC color="warning" onClick={() => navigate(-1)} small>
                        Back
                    </ButtonC>
                </GridFlexBox>
                <GridFlexBox fe w="50%">
                    <ButtonC color="error" onClick={() => navigate("/sign-out")} small>
                        Sign Out
                    </ButtonC>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
        </Container>
    );
}

function SignInAsMember(props) {
    return (
        <React.Fragment>
            {props.state && props.state.authUser && props.state.authUser.role === "Super Admin" && (
                <React.Fragment>
                    <GridItem fs fs20 w="30%">
                        Sign in as:
                    </GridItem>
                    <GridFlexBox fs w="70%">
                        <ButtonC small onClick={props.handleClick}>
                            {props.signInAs ? props.signInAs.displayName : "Select member"}
                        </ButtonC>
                    </GridFlexBox>
                    <GridDivider />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
