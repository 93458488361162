import { Button, Container } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import { styled } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import CustomDate from "../components/CustomDate";
import FullDialog from "../components/FullDialog";
import { SalesChartComponent } from "../components/SalesChartComponent";
import { AuthContext } from "../context/AuthContext";
import { SalesContext } from "../context/SalesContext";
import formatDate from "../utils-functions/formatDate";
import formatNumber from "../utils-functions/formatNumber";
import getDayMonthYear from "../utils-functions/getDayMonthYear";
import getSalesRanking, { getTotalSales } from "../functions/getSalesRanking";
import getSalesRankingByDate from "../functions/getSalesRankingByDate";
import loadSales from "../functions/loadSales";
import { GridContainer, GridDivider, GridFlexBox, IconC, Loading, Name, Title } from "../themes/themes";

const Status = styled("button")((props) => {
    let { clicked, bgcolor = orange[700] } = props;
    if (!clicked) bgcolor = "lightgray";
    return {
        border: "solid 1px",
        borderRadius: "7px",
        color: "white",
        borderColor: bgcolor,
        backgroundColor: bgcolor,
        fontSize: "12px",
        paddingLeft: "10px",
        paddingRight: "10px",
        cursor: "pointer"
    };
});

const DATE = new Date();
const MONTH = parseInt(getDayMonthYear(DATE).month);
const YEAR = parseInt(getDayMonthYear(DATE).year);

export default function ConversionChartPage() {
    const { user } = useContext(AuthContext);
    const { sales, setSales, yearsLoaded } = useContext(SalesContext);
    const [loading, setLoading] = useState(false);
    const [salesRankingByMonth, setSalesRankingByMonth] = useState();
    const [salesRankingByYear, setSalesRankingByYear] = useState();
    const [salesRankingByDates, setSalesRankingByDates] = useState();
    const [month, setMonth] = useState(MONTH);
    const [year, setYear] = useState(YEAR);
    const [totalSalesByDates, setTotalSalesByDates] = useState(0);
    const [totalSalesByMonth, setTotalSalesByMonth] = useState(0);
    const [totalSalesByYear, setTotalSalesByYear] = useState(0);
    const [type, setType] = useState(["project", "subsale"]);
    const [status, setStatus] = useState(["BOOKED", "BILLED", "TO-INVOICE"]);
    const [display, setDisplay] = useState("month");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [openCustomDate, setOpenCustomDate] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const sales = await loadSales(user, year, year, yearsLoaded);
            const sales2 = await loadSales(user, year - 1, year - 1, yearsLoaded);
            setSales([...sales, ...sales2]);
            setLoading(false);
        };
        if (!sales) getData();
    }, []);

    const update = () => {
        const { salesRankingArrayByMonth, salesRankingArrayByYear } = getSalesRanking(
            sales,
            month,
            year,
            type,
            status,
            "spaSignedDate"
        );
        let salesRankingArrayByDates = [];
        if (startDate && endDate) {
            salesRankingArrayByDates = getSalesRankingByDate(
                sales,
                startDate,
                endDate,
                type,
                status,
                "spaSignedDate"
            ).salesRankingArray;
            setSalesRankingByDates(salesRankingArrayByDates);
        }

        const totalSalesByDates = getTotalSales(salesRankingArrayByDates);
        const totalSalesByMonth = getTotalSales(salesRankingArrayByMonth);
        const totalSalesByYear = getTotalSales(salesRankingArrayByYear);
        setSalesRankingByMonth(salesRankingArrayByMonth);
        setSalesRankingByYear(salesRankingArrayByYear);
        setTotalSalesByMonth(totalSalesByMonth);
        setTotalSalesByYear(totalSalesByYear);
        setTotalSalesByDates(totalSalesByDates);
    };

    useEffect(() => {
        update();
    }, [sales, month, year, status, type, startDate, endDate]);

    const handleBack = async () => {
        setDisplay("month");
        if (month === 1) {
            setMonth(12);
            const newYear = year - 1;
            setYear(newYear);
            setLoading(true);
            const sales2 = await loadSales(user, newYear, newYear, yearsLoaded);
            if (sales2) {
                const newSales = [...sales, ...sales2];
                setSales(newSales);
            }

            setLoading(false);
            return;
        }
        setMonth(month - 1);
    };

    const handleForward = () => {
        setDisplay("month");
        if (month === 12) {
            setMonth(1);
            setYear(year + 1);
            return;
        }
        setMonth(month + 1);
    };

    const checkStatus = (value) => {
        switch (value) {
            case "booked":
                if (status.includes("BOOKED")) return "true";
                break;
            case "converted":
                if (status.includes("BILLED") || status.includes("TO-INVOICE")) return "true";
                break;
            case "cancelled":
                if (status.includes("CANCELLED")) return "true";
                break;
            default:
                return;
        }
    };

    const checkType = (value) => {
        if (type.includes(value)) return "true";
        return;
    };

    const handleClickStatus = (value) => {
        console.log(value);
        switch (value) {
            case "booked":
                updateState(["BOOKED"], setStatus, status);
                break;
            case "converted":
                updateState(["BILLED", "TO-INVOICE"], setStatus, status);
                break;
            case "cancelled":
                updateState(["CANCELLED"], setStatus, status);
                break;
            default:
                return;
        }
    };

    const handleClickType = (value) => {
        switch (value) {
            case "project":
                updateState(["project"], setType, type);
                break;
            case "subsale":
                updateState(["subsale"], setType, type);
                break;
            case "rental":
                updateState(["rental"], setType, type);
                break;
            default:
                return;
        }
    };

    let salesRanking;
    let totalSales;
    if (display === "month") {
        salesRanking = salesRankingByMonth;
        totalSales = totalSalesByMonth;
    } else {
        salesRanking = salesRankingByYear;
        totalSales = totalSalesByYear;
    }
    if (startDate && endDate) {
        salesRanking = salesRankingByDates;
        totalSales = totalSalesByDates;
    }

    function updateState(s, setStatusFunction, state) {
        let newState = [...state];

        s.forEach((a) => {
            if (state.includes(a)) {
                let index = newState.findIndex((b) => b === a);
                newState.splice(index, 1);
            } else {
                newState = [...newState, a];
            }
        });

        setStatusFunction(newState);
    }

    const handleChangeDisplay = () => {
        setStartDate();
        setEndDate();
        if (display === "month") {
            setDisplay("year");
        } else setDisplay("month");
    };

    const handleCustomDate = async () => {
        setOpenCustomDate(true);
    };

    const setCustomDate = (response) => {
        setOpenCustomDate(false);
        if (response) {
            setStartDate(response.start);
            setEndDate(response.end);
        }
    };

    const clearCustomDates = () => {
        setStartDate();
        setEndDate();
    };

    return (
        <Container maxWidth={"sm"} disableGutters>
            <GridContainer spacing={0}>
                <GridFlexBox>
                    <Title>Conversions Chart</Title>
                </GridFlexBox>
                <GridFlexBox p0>
                    <Name fs12>Sales amount based on SPA Signed Date</Name>
                </GridFlexBox>{" "}
                <GridFlexBox p0>
                    <Name fs12>{formatDate(new Date())}</Name>
                </GridFlexBox>
                <GridFlexBox>
                    {!startDate ? (
                        <>
                            <IconC onClick={handleBack} cp>
                                arrow_back
                            </IconC>
                            {display === "month" && <Name>{month}</Name>}
                            {display === "month" && <Name>{" | "}</Name>}
                            <Name>{year}</Name>
                            <IconC onClick={handleForward} cp>
                                arrow_forward
                            </IconC>
                        </>
                    ) : (
                        <>
                            <Name wrap fs12>
                                {startDate}
                            </Name>
                            <Name wrap fs12>
                                {endDate}
                            </Name>
                            <IconC onClick={clearCustomDates}>clear</IconC>
                        </>
                    )}
                    <Button size="small" onClick={handleChangeDisplay}>
                        {display === "month" ? "By Year" : "By Month"}
                    </Button>
                    <Button size="small" onClick={handleCustomDate}>
                        Custom Date
                    </Button>
                </GridFlexBox>
                <GridFlexBox xs={12} md={6}>
                    <Status bgcolor={green[600]} clicked={checkStatus("converted")}>
                        Converted
                    </Status>
                    {user.role === "Super Admin" && (
                        <Status
                            bgcolor={red[600]}
                            clicked={checkStatus("cancelled")}
                            onClick={() => handleClickStatus("cancelled")}
                        >
                            Cancelled
                        </Status>
                    )}
                </GridFlexBox>
                <GridFlexBox xs={12} md={6}>
                    <Status clicked={checkType("project")} onClick={() => handleClickType("project")}>
                        Projects
                    </Status>
                    <Status clicked={checkType("subsale")} onClick={() => handleClickType("subsale")}>
                        Subsales
                    </Status>
                    <Status clicked={checkType("rental")} onClick={() => handleClickType("rental")}>
                        Rental
                    </Status>
                </GridFlexBox>
                <GridFlexBox>
                    <Name>Total net value: </Name>
                    <Name>{formatNumber(totalSales, 0)}</Name>
                </GridFlexBox>
                <GridDivider ptb={10} />
                <Loading loading={loading} />
                {salesRanking &&
                    salesRanking.map((sale, i) => (
                        <SalesChartComponent key={i} i={i} sale={sale} salesRanking={salesRanking} />
                    ))}
            </GridContainer>
            <FullDialog
                open={openCustomDate}
                handleClose={setCustomDate}
                title={"Set Custom Date"}
                Component={<CustomDate handleClose={setCustomDate} />}
            />
        </Container>
    );
}
