import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { COPYRIGHT_LINK, WEBSITE_NAME } from "../settings/settings";
import { signOut } from "../functions/signOut";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { UsersContext } from "../context/UsersContext";
import { SalesContext } from "../context/SalesContext";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href={COPYRIGHT_LINK}>
                {WEBSITE_NAME}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export default function SignOut() {
    const { setUser, setAuthUser } = useContext(AuthContext);
    const { setUsers } = useContext(UsersContext);
    const { setSales, setYearsLoaded } = useContext(SalesContext);
    const navigate = useNavigate();

    const exit = async () => {
        await signOut();
        setUser(null);
        setAuthUser(null);
        setUsers();
        setSales();
        setYearsLoaded([]);
        localStorage.removeItem("salesPageMemory");
        localStorage.removeItem("localUsers");
        navigate("/");
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Button onClick={exit} variant="contained" color="error">
                    Click here to Sign Out
                </Button>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}
