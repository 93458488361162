import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { removeDuplicate } from "../utils-functions/removeDuplicate";
import populateInvolveInUsers from "../seeding-functions/populateInvolveInUsers";
import { findUserInAtlas } from "./findUserInAtlas";

export default async function findMissingUpline(users) {
    let n = [];
    users.forEach((user) => {
        const f = user.involveId.find((id) => id === "daveckw@gmail.com");
        if (!f) {
            const lastInvolve = user.involve[user.involve.length - 1];
            const lastInvolveUser = users.find(
                (user) => user.agent === lastInvolve
            );
            n.push({
                directLeader: lastInvolveUser.directLeader,
                agent: lastInvolveUser.agent
            });
        }
    });
    n = removeDuplicate(n);
    console.log(n);
    const missing = await Promise.all(
        n.map(async (userName) => {
            const upline = await findUserInAtlas(userName);
            const userData = {
                agent: upline.agentName,
                branch: upline.branch,
                email: upline.email,
                directLeader: upline.groupLeaderName,
                tel: upline.mobileNumber,
                status: upline.status,
                joinedDate: new Date("2017-01-01")
            };

            console.log(userData);

            const docRef = doc(db, "users", upline.email.toLowerCase());
            await setDoc(docRef, userData, { merge: true });
            await populateInvolveInUsers([userData]);
            console.log(userData.agent, " added to Database");
            return upline.agentName;
        })
    );
    console.log(missing);
}
