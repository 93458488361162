import { Container } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { useContext, useMemo, useState } from "react";
import { GridContainer, GridFlexBox, Name } from "../../themes/themes";
import convertDate from "../../utils-functions/convertDate";
import { AuthContext } from "./../../context/AuthContext";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

const EVENTS = [
    { title: "Test", date: new Date(), id: "1" },
    { title: "Test 2", date: "2023-06-15", id: "2" },
    { title: "Test 3", date: "2023-06-15", id: "3" },
];

export default function CalendarPage() {
    const [events, setEvents] = useState(EVENTS);
    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(true);
    const [unsubscribe, setUnsubscribe] = useState();

    const displayEvents = useMemo(() => {
        let array = [];
        events.forEach((event) => {
            array.push({
                title: `${event.title}`,
                start: new Date(convertDate(event.date)),
                id: event.id,
                type: "appointments",
                backgroundColor: yellow[800]
            });
        });

        return array;
    }, [events]);

    const handleDateClick = (e) => {};

    return (
        <Container maxWidth="md" disableGutters>
            <FullCalendar
                height={"65vh"}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                titleFormat={{ year: "numeric", month: "short" }}
                weekends={true}
                firstDay={1}
                events={displayEvents || []}
                eventContent={renderEventContent}
                eventClick={handleDateClick}
            />
        </Container>
    );
}

// a custom render function
function renderEventContent(eventInfo) {
    const { backgroundColor } = eventInfo.event;

    return (
        <GridContainer>
            <GridFlexBox fs bgcolor={backgroundColor}>
                <Name fs10 nowrap color="white">
                    {eventInfo.event.title}
                </Name>
            </GridFlexBox>
        </GridContainer>
    );
}
