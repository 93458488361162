export default async function chatGPT(prompt, setMessages, messages, model, setMessage = null) {
    let msgs = [
        { role: "system", content: "You are a helpful assistant who answer questions from the user" },
        { role: "user", content: prompt }
    ];

    const chatHistory = messages.map((msg) => ({ role: msg.role, content: msg.content }));
    msgs.splice(1, 0, ...chatHistory);
    if (msgs.length > 2) {
        msgs.pop();
    }
    console.log(msgs);

    try {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
            },
            body: JSON.stringify({
                model: model,
                messages: msgs,
                temperature: 0.5,
                stream: true,
                n: 1
            })
        };

        const response = await fetch("https://api.openai.com/v1/chat/completions", requestOptions);

        // This data is a ReadableStream
        const data = response.body;
        if (!data) {
            return;
        }

        const reader = data.getReader();
        const decoder = new TextDecoder();
        let done = false;

        let text = "";

        while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            const chunkValue = decoder.decode(value);

            // Split chunkValue by the string "data: "
            const parts = chunkValue.split("data: ");

            // Remove the first element if it is empty
            if (parts[0].trim() === "") {
                parts.shift();
            }

            // Parse each part as a JSON object
            const jsonObjectArray = parts
                .map((part) => {
                    // Check if the part is "[DONE]" and skip processing it
                    if (part.trim() === "[DONE]") {
                        return null;
                    }

                    try {
                        return JSON.parse(part);
                    } catch (error) {
                        console.error("Failed to parse JSON:", part, error);
                        return null;
                    }
                })
                .filter((obj) => obj !== null);

            jsonObjectArray.forEach((jsonObj) => {
                const content = jsonObj.choices[0].delta?.content || "";
                text += content;
            });

            if (setMessage) {
                setMessage(text);
            } else {
                setMessages((prevMessages) => {
                    let newMessages = [...messages, { name: "DJC AI", content: text, role: "assistant" }];
                    return newMessages;
                });
            }
        }

        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
}
