import { Box, Container } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import MemberDetails from "../components/MemberDetails";
import PaginationComponent from "../components/PaginationComponent";
import SearchComponent from "../components/SearchComponent";
import { AuthContext } from "../context/AuthContext";
import { UsersContext } from "../context/UsersContext";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import convertToLowerCase from "../utils-functions/convertToLowerCase";
import formatNumber from "../utils-functions/formatNumber";
import loadUsers from "../functions/loadUsers";
import { GridContainer, GridDivider, GridFlexBox, Loading, Name, Title } from "../themes/themes";
import { useNavigate } from "react-router-dom";

export default function UsersPage() {
    const { users, setUsers } = useContext(UsersContext);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const [page, setPage] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        const getUsers = async () => {
            setLoading(true);
            const users = await loadUsers(user);
            setUsers(users);
            setLoading(false);
        };
        if (!users) getUsers();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const handleSearch = (value) => {
        setPage(1);
        setSearch(value);
    };

    const checkUser = (user) => {
        console.log(user);
        navigate("/user-card-page/" + user.id);
    };

    let filteredUsers = users;
    let totalPage = 1;
    if (!arrayIsEmpty(filteredUsers)) {
        filteredUsers = filteredUsers.filter((user) => {
            const searchField =
                convertToLowerCase(user.fullName) + convertToLowerCase(user.agent) + convertToLowerCase(user.email);
            return searchField.includes(convertToLowerCase(search));
        });
        totalPage = Math.ceil(filteredUsers.length / 50);
    }

    return (
        <Box m={1}>
            <Container maxWidth="sm">
                <GridContainer>
                    <GridFlexBox>
                        <Title>Users Page</Title>
                    </GridFlexBox>
                    <GridFlexBox>
                        <PaginationComponent page={page} totalPage={totalPage} setPage={setPage} />
                        <Name>
                            Total records: &nbsp;
                            {filteredUsers && formatNumber(filteredUsers.length, 0)}
                        </Name>
                    </GridFlexBox>
                    <GridFlexBox>
                        <SearchComponent handleSearch={handleSearch} search={search} />
                    </GridFlexBox>
                    <GridDivider />
                    <Loading loading={loading} />
                    {filteredUsers &&
                        filteredUsers
                            .slice((page - 1) * 50, page * 50)
                            .map((user, i) => <MemberDetails key={i} checkUser={checkUser} user={user} />)}
                    <GridFlexBox>
                        <PaginationComponent page={page} totalPage={totalPage} setPage={setPage} />
                        <Name>
                            Total records: &nbsp;
                            {filteredUsers && formatNumber(filteredUsers.length, 0)}
                        </Name>
                    </GridFlexBox>
                </GridContainer>
            </Container>
        </Box>
    );
}
