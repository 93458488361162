import { Container } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { SalesContext } from "../context/SalesContext";
import { UsersContext } from "../context/UsersContext";
import formatNumber from "../utils-functions/formatNumber";
import initializeData from "../functions/intializeData";
import { populateNumberOfInvolve } from "../test-functions/populateNumberOfInvolve";
import {
    ButtonC,
    GridContainer,
    GridFlexBox,
    Loading,
    Name,
    Title
} from "../themes/themes";

export const PopulateNumberOfInvolvePage = () => {
    const { user } = useContext(AuthContext);
    const { users, setUsers } = useContext(UsersContext);
    const { sales, setSales, yearsLoaded } = useContext(SalesContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initializeData(users, setUsers, sales, setSales, user, setLoading, yearsLoaded);
    }, []);

    const handleClick = () => {
        populateNumberOfInvolve(users, sales)
    };

    return (
        <Container maxWidth={"md"}>
            <GridContainer>
                <GridFlexBox>
                    <Title>Populate number of involve in Users</Title>
                </GridFlexBox>
                <GridFlexBox>
                    <Name>
                        Number of Sales Loaded:{" "}
                        {sales && formatNumber(sales.length, 0)}
                    </Name>
                </GridFlexBox>
                <GridFlexBox>
                    <Name>
                        Number of Users Loaded:{" "}
                        {users && formatNumber(users.length, 0)}
                    </Name>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridFlexBox>
                    <ButtonC onClick={handleClick}>Populate</ButtonC>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
};
