import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./context/AuthContext";
import { ReactNotifications } from "react-notifications-component";
import { ListProvider } from "./context/ListContext";
import { UsersProvider } from "./context/UsersContext";
import { SalesProvider } from "./context/SalesContext";
import { DialogProvider } from "./context/DialogContext";
import { ProjectsProvider } from "./context/ProjectsContext";
import { StateProvider } from "./context/StateContext";
import { InputProvider } from "./context-utils/InputContext";
import "./index.css";
import { Toaster } from "react-hot-toast";
import { ConfirmationProvider } from "./context-utils/ConfirmationContext";
import { StoreProvider } from "./context/StoreContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <ReactNotifications />
        <BrowserRouter>
            <ConfirmationProvider>
                <DialogProvider>
                    <StoreProvider>
                        <ListProvider>
                            <UsersProvider>
                                <InputProvider>
                                    <StateProvider>
                                        <SalesProvider>
                                            <ProjectsProvider>
                                                <AuthProvider>
                                                    <CssBaseline />
                                                    <App className="app" />
                                                    <Toaster />
                                                </AuthProvider>
                                            </ProjectsProvider>
                                        </SalesProvider>
                                    </StateProvider>
                                </InputProvider>
                            </UsersProvider>
                        </ListProvider>
                    </StoreProvider>
                </DialogProvider>
            </ConfirmationProvider>
        </BrowserRouter>
    </React.Fragment>
);
