export const campaigns_2023 = [
    {
        name: "Seoul Campaign 2023",
        projects: [
            "Ceylonz",
            "The Fiddlewoodz @ KL Metropolis",
            "Mossaz @ Empire City",
            "Paxtonz @ Empire City",
            "D'Ivo Residences @ Old Klang Road",
            "D'Vine (550sqft) @ Central Park Damansara",
            "D'Clover @ Central Park Damansara",
            "D'Terra @ Central Park Damansara",
            "D'Tessera @ Central Park Damansara"
        ],
        startDate: new Date("2023-01-28"),
        endDate: new Date("2023-03-31"),
        value: 1000000,
        conversion: 30,
        special: [
            { project: "D'Ivo Residences @ Old Klang Road", multiplier: 1.5 },
            { project: "D'Terra @ Central Park Damansara", multiplier: 1.5 },
            { project: "D'Clover @ Central Park Damansara", multiplier: 1.5 }
        ]
    },
    {
        name: "Osaka Campaign 2023",
        projects: [
            "The Fiddlewoodz @ KL Metropolis",
            "Mossaz @ Empire City",
            "Paxtonz @ Empire City",
            "D'Ivo Residences @ Old Klang Road",
            "D'Vine (550sqft) @ Central Park Damansara",
            "D'Clover @ Central Park Damansara",
            "D'Terra @ Central Park Damansara",
            "D'Tessera @ Central Park Damansara",
            "The Stallionz"
        ],
        startDate: new Date("2023-04-01"),
        endDate: new Date("2023-05-31"),
        value: 1000000,
        number: 3,
        conversion: 45,
        special: []
    }
];

export const campaigns_2024 = [
    {
        name: "IQI World Club 2024",
        special: [
            // Bonus Booster x2 Campaign
            {
                project: "Branniganz Suites @ Bukit Bintang",
                multiplier: 2,
                startDate: "2024-06-13",
                endDate: "2024-07-13",
                conversion: 30
            },

            {
                project: "Hanaz Suites @ KL City Centre",
                multiplier: 2,
                startDate: "2024-06-13",
                endDate: "2024-07-13",
                conversion: 30
            },

            // Branniganz Bonus Campaign
            {
                project: "Branniganz Suites @ Bukit Bintang",
                multiplier: 2,
                startDate: "2024-03-14",
                endDate: "2024-03-31",
                conversion: 30
            },

            // x2 Bonus Booster Campaign
            {
                project: "D'Clover @ Central Park Damansara",
                multiplier: 2,
                startDate: "2024-01-01",
                endDate: "2024-02-15",
                conversion: 30
            },
            {
                project: "D'Terra @ Central Park Damansara",
                multiplier: 2,
                startDate: "2024-01-01",
                endDate: "2024-02-15",
                conversion: 30
            },
            {
                project: "D'Tessera @ Central Park Damansara",
                multiplier: 2,
                startDate: "2024-01-01",
                endDate: "2024-02-15",
                conversion: 30
            },
            {
                project: "D'Ivo Residences @ Old Klang Road",
                multiplier: 2,
                startDate: "2024-01-01",
                endDate: "2024-02-15",
                conversion: 30
            },
            {
                project: "Veladaz Residences @ Bukit Jalil",
                multiplier: 2,
                startDate: "2024-01-01",
                endDate: "2024-02-15",
                conversion: 30
            },
            {
                project: "Branniganz Suites @ Bukit Bintang",
                multiplier: 2,
                startDate: "2024-01-01",
                endDate: "2024-02-15",
                conversion: 30
            },
            {
                project: "The Stallionz",
                multiplier: 2,
                startDate: "2024-01-01",
                endDate: "2024-02-15",
                conversion: 30
            },
            {
                project: "Noordinz Suites - George Town",
                multiplier: 2,
                startDate: "2024-01-01",
                endDate: "2024-02-15",
                conversion: 30
            },

            // x3 Bonus Booster Campaign
            {
                project: "D'Clover @ Central Park Damansara",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            },
            {
                project: "D'Terra @ Central Park Damansara",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            },
            {
                project: "D'Tessera @ Central Park Damansara",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            },
            {
                project: "D'Ivo Residences @ Old Klang Road",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            },
            {
                project: "Veladaz Residences @ Bukit Jalil",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            },
            {
                project: "Branniganz Suites @ Bukit Bintang",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            },
            {
                project: "The Stallionz",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            },
            {
                project: "Noordinz Suites - George Town",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            },
            {
                project: "Qubaz @ Kuala Terengganu",
                multiplier: 3,
                startDate: "2024-02-16",
                endDate: "2024-02-29",
                conversion: 30
            }
        ],
        projects: [
            "The Kingswoodz @ Bukit Jalil",
            "Branniganz Suites @ Bukit Bintang",
            "Hanaz Suites @ KL City Centre",
            "Veladaz Residences @ Bukit Jalil",
            "Noordinz Suites - George Town",
            "Kyliez Suites @ KL City Centre",
            "D'Tessera @ Central Park Damansara",
            "Qubaz @ Kuala Terengganu",
            "Residensi Bayu Damai @ Kota Kinabalu",
            "D'Terra @ Central Park Damansara",
            "D'Erica @ Central Park Damansara",
            "D'Ivo Residences @ Old Klang Road",
            "D'Clover @ Central Park Damansara",
            "Mossaz @ Empire City",
            "The Stallionz"
        ],
        startDate: new Date("2024-01-01"),
        endDate: new Date("2024-12-31"),
        value: 10000000,
        conversion: 31,
        conversionDate: "2025-01-31"
    }
];
