import { Container } from "@mui/material";
import { orange } from "@mui/material/colors";
import { styled } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { SalesChartComponent } from "../components/SalesChartComponent";
import { SalesHeaderComponent } from "../components/SalesHeaderComponent";
import { AuthContext } from "../context/AuthContext";
import { ProjectsContext } from "../context/ProjectsContext";
import { SalesContext } from "../context/SalesContext";
import getProjects from "../functions/getProjects";
import getSalesRanking, { getTotalSales } from "../functions/getSalesRanking";
import getSalesRankingByDate from "../functions/getSalesRankingByDate";
import loadSales from "../functions/loadSales";
import { GridContainer, GridDivider, Loading } from "../themes/themes";
import getDayMonthYear from "../utils-functions/getDayMonthYear";
import { useMemo } from "react";

export const Status = styled("button")((props) => {
    let { clicked, bgcolor = orange[700] } = props;
    if (!clicked) bgcolor = "lightgray";
    return {
        border: "solid 1px",
        borderRadius: "7px",
        color: "white",
        borderColor: bgcolor,
        backgroundColor: bgcolor,
        fontSize: "12px",
        paddingLeft: "10px",
        paddingRight: "10px",
        cursor: "pointer"
    };
});

const DATE = new Date();
const MONTH = parseInt(getDayMonthYear(DATE).month);
const YEAR = parseInt(getDayMonthYear(DATE).year);

export default function SalesChartPage() {
    const { user } = useContext(AuthContext);
    const { sales, setSales, yearsLoaded } = useContext(SalesContext);
    const { projects, setProjects } = useContext(ProjectsContext);

    const [loading, setLoading] = useState(false);

    const [salesRankingByMonth, setSalesRankingByMonth] = useState();
    const [commRankingByMonth, setCommRankingByMonth] = useState();
    const [salesRankingByYear, setSalesRankingByYear] = useState();
    const [commRankingByYear, setCommRankingByYear] = useState();
    const [salesRankingByDates, setSalesRankingByDates] = useState();
    const [commRankingByDates, setCommRankingByDates] = useState();

    const [totalSalesByDates, setTotalSalesByDates] = useState(0);
    const [totalSalesByMonth, setTotalSalesByMonth] = useState(0);
    const [totalSalesByYear, setTotalSalesByYear] = useState(0);
    const [totalCommByDates, setTotalCommByDates] = useState(0);
    const [totalCommByMonth, setTotalCommByMonth] = useState(0);
    const [totalCommByYear, setTotalCommByYear] = useState(0);

    const [chartType, setChartType] = useState("Sales");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [month, setMonth] = useState(MONTH);
    const [year, setYear] = useState(YEAR);
    const [type, setType] = useState(["project", "subsale"]);
    const [display, setDisplay] = useState("year");
    const [status, setStatus] = useState(["BOOKED", "BILLED", "TO-INVOICE"]);

    useEffect(() => {
        const getSalesData = async () => {
            setLoading(true);
            const sales = await loadSales(user, year, year, yearsLoaded);
            await getProjectsData(sales);
            setSales(sales);
            setLoading(false);
        };

        const getProjectsData = async (sales) => {
            const projects = await getProjects(sales);
            setProjects(projects);
        };

        if (!sales) getSalesData();
        if (sales && !projects) getProjectsData(sales);
    }, []);

    const update = () => {
        const { salesRankingArrayByMonth, salesRankingArrayByYear, commRankingArrayByMonth, commRankingArrayByYear } =
            getSalesRanking(sales, month, year, type, status, "bookingDate", projects);
        let salesRankingArray = [];
        let commRankingArray = [];
        if (startDate && endDate) {
            ({ salesRankingArray, commRankingArray } = getSalesRankingByDate(
                sales,
                startDate,
                endDate,
                type,
                status,
                "bookingDate",
                projects
            ));
            setSalesRankingByDates(salesRankingArray);
            setCommRankingByDates(commRankingArray);
        }

        const totalSalesByDates = getTotalSales(salesRankingArray);
        const totalSalesByMonth = getTotalSales(salesRankingArrayByMonth);
        const totalSalesByYear = getTotalSales(salesRankingArrayByYear);
        const totalCommByDates = getTotalSales(commRankingArray);
        const totalCommByMonth = getTotalSales(commRankingArrayByMonth);
        const totalCommByYear = getTotalSales(commRankingArrayByYear);
        setSalesRankingByMonth(salesRankingArrayByMonth);
        setCommRankingByMonth(commRankingArrayByMonth);
        setSalesRankingByYear(salesRankingArrayByYear);
        setCommRankingByYear(commRankingArrayByYear);
        setTotalSalesByMonth(totalSalesByMonth);
        setTotalSalesByYear(totalSalesByYear);
        setTotalSalesByDates(totalSalesByDates);
        setTotalCommByMonth(totalCommByMonth);
        setTotalCommByYear(totalCommByYear);
        setTotalCommByDates(totalCommByDates);
    };

    useEffect(() => {
        update();
    }, [sales, month, year, projects, status, type, startDate, endDate]);

    const checkStatus = (value) => {
        switch (value) {
            case "booked":
                if (status.includes("BOOKED")) return "true";
                break;
            case "converted":
                if (status.includes("BILLED") || status.includes("TO-INVOICE")) return "true";
                break;
            case "cancelled":
                if (status.includes("CANCELLED")) return "true";
                break;
            default:
                return;
        }
    };

    const checkType = (value) => {
        if (type.includes(value)) return "true";
        return;
    };

    const [salesRanking, totalSales] = useMemo(() => {
        let salesRanking;
        let commRanking;
        let totalSales;
        let totalComm;
        if (display === "month") {
            salesRanking = salesRankingByMonth;
            commRanking = commRankingByMonth;
            totalSales = totalSalesByMonth;
            totalComm = totalCommByMonth;
        } else {
            salesRanking = salesRankingByYear;
            commRanking = commRankingByYear;
            totalSales = totalSalesByYear;
            totalComm = totalCommByYear;
        }
        if (startDate && endDate) {
            salesRanking = salesRankingByDates;
            commRanking = commRankingByDates;
            totalSales = totalSalesByDates;
            totalComm = totalCommByDates;
        }

        if (chartType === "Commissions") {
            salesRanking = commRanking;
            totalSales = totalComm;
        }
        return [salesRanking, totalSales];
    }, [
        display,
        salesRankingByMonth,
        commRankingByMonth,
        totalSalesByMonth,
        totalCommByMonth,
        salesRankingByYear,
        commRankingByYear,
        totalSalesByYear,
        totalCommByYear,
        chartType,
        startDate,
        endDate,
        salesRankingByDates,
        commRankingByDates,
        totalSalesByDates,
        totalCommByDates
    ]);

    return (
        <Container maxWidth={"sm"} disableGutters>
            <GridContainer spacing={0}>
                <SalesHeaderComponent
                    chartType={chartType}
                    setChartType={setChartType}
                    startDate={startDate}
                    display={display}
                    setDisplay={setDisplay}
                    month={month}
                    setMonth={setMonth}
                    year={year}
                    setYear={setYear}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    checkStatus={checkStatus}
                    user={user}
                    checkType={checkType}
                    totalSales={totalSales}
                    setTypes={setType}
                    setLoading={setLoading}
                    sales={sales}
                    setSales={setSales}
                    yearsLoaded={yearsLoaded}
                    status={status}
                    setStatus={setStatus}
                    type={type}
                    setType={setType}
                />

                <Loading loading={loading} />
                {salesRanking &&
                    salesRanking.map((sale, i) => (
                        <SalesChartComponent key={i} i={i} sale={sale} salesRanking={salesRanking} />
                    ))}
                <GridDivider ptb={"16px"} />
            </GridContainer>
        </Container>
    );
}
