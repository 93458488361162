import { Button, Grid, Paper, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import UploadExcel from "../components/UploadExcel";
import { AuthContext } from "../context/AuthContext";
import { SalesContext } from "../context/SalesContext";
import { UsersContext } from "../context/UsersContext";
import initializeData from "../functions/intializeData";
import seedSales from "../seeding-functions/seedSales";
import { headerName as header } from "../tests/salesHeader";
import { Loading } from "../themes/themes";
import TypingEffect from "../components/TypingEffects/TypingEffect";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import { ConfirmationContext } from "../context-utils/ConfirmationContext";
import { collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

const MyButton = styled(Button)({
    margin: 5
});

export default function SeedSales() {
    const [data, setData] = useState();
    const [messages, setMessages] = useState([]);
    const [done, setDone] = useState(false);

    const { users, setUsers } = useContext(UsersContext);
    const { sales, setSales } = useContext(SalesContext);
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initializeData(users, setUsers, sales, setSales, user, setLoading);
    }, []);

    const upload = (data) => {
        console.log(data);
        setData(data);
    };

    const clickSeedSales = async (data, users, sales) => {
        setLoading(true);
        await seedSales(data, users, sales, setMessages);
        setLoading(false);
    };

    const { openConfirmation } = useContext(ConfirmationContext);

    const handleDeleteAll = async () => {};

    return (
        <Box m={1}>
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent={"center"}>
                        <Typography variant="h5">Seed Sales</Typography>
                    </Box>
                    <Loading loading={loading} />
                    <Box>
                        <UploadExcel upload={upload} customHeader={header} />
                    </Box>
                    <Box>
                        <MyButton variant="contained" color="error" onClick={() => clickSeedSales(data, users, sales)}>
                            Upload Sales Data
                        </MyButton>
                    </Box>
                    <Box>
                        <Button variant="contained" color="error" onClick={() => handleDeleteAll()}>
                            Delete all 2024
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {!arrayIsEmpty(messages) && (
                <Box
                    style={{
                        width: "80vw",
                        height: "50vh",
                        position: "fixed",
                        top: "100px"
                    }}
                >
                    <TypingEffect sentences={messages} speed={1} setDone={setDone} />
                </Box>
            )}
        </Box>
    );
}
