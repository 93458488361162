import { Icon, styled, Typography } from "@mui/material";
import { blue, orange } from "@mui/material/colors";
import React from "react";
import convertDate from "../utils-functions/convertDate";
import formatDate from "../utils-functions/formatDate";
import formatNumber from "../utils-functions/formatNumber";
import { GridDivider, GridFlexBox, IconC, Name } from "../themes/themes";

const Status = styled(Typography)(({ status }) => {
    let bgColor = "darkcyan";
    switch (status) {
        case "BOOKED":
            bgColor = "green";
            break;
        case "CANCELLED":
            bgColor = "red";
            break;
        default:
            bgColor = "darkcyan";
    }
    return {
        border: "solid 1px",
        borderRadius: "7px",
        fontSize: 9,
        padding: 1,
        paddingLeft: 4,
        paddingRight: 4,
        color: "white",
        backgroundColor: bgColor
    };
});

const IconOpen = ({ handleOpen, sale }) => {
    return (
        <Icon
            onClick={() => {
                handleOpen(sale);
            }}
            style={{
                display: handleOpen ? "block" : "none",
                cursor: "pointer",
                fontSize: "17px"
            }}
        >
            open_in_new
        </Icon>
    );
};

export default function SaleDetails({ handleOpen, handleClickFace, sale, sales, existed = false }) {
    const saleProject = (sale) => {
        if (sale.project) {
            return `${sale.project} ${sale.unitNo}`;
        } else {
            return `${sale.unitNo}`;
        }
    };

    return (
        <React.Fragment>
            {!existed && (
                <>
                    <GridDivider ptb={10} />
                    <GridFlexBox xs={4} fs p0>
                        <IconOpen handleOpen={handleOpen} sale={sale}></IconOpen>
                        <Name>{`${formatDate(convertDate(sale.bookingDate))}`}</Name>
                    </GridFlexBox>
                    <GridFlexBox overflow xs={8} fs p0>
                        <Name wrap>{saleProject(sale)}</Name>
                    </GridFlexBox>
                    <GridFlexBox xs={9} fs p0>
                        <IconC color={orange[600]}>sell</IconC>
                        <Name>{`Net Price: ${formatNumber(sale.netPrice)}`}</Name>
                    </GridFlexBox>
                    <GridFlexBox xs={3} p0>
                        <Status status={sale.type}>{sale.type}</Status>
                        <Status status={sale.status}>{sale.status}</Status>
                    </GridFlexBox>

                    <GridFlexBox fs p0>
                        <IconC color="darkcyan">house</IconC>
                        <Name color="darkcyan">{`${sale.location}`}</Name>
                        <IconC color="green">{sale.buyerName && "perm_identity"}</IconC>
                        <Name>{sale.buyerName && `${sale.buyerName}`}</Name>
                    </GridFlexBox>
                </>
            )}
            {sales
                ? sales.map((sale, i) => displayMembers(handleClickFace, sale, i))
                : displayMembers(handleClickFace, sale)}
        </React.Fragment>
    );
}

function displayMembers(handleClickFace, sale, i = 0) {
    return (
        <>
            <GridFlexBox key={sale.id} fs p0>
                <IconC color={blue[500]} onClick={() => handleClickFace(sale.agent)} cp>
                    account_circle
                </IconC>
                <Name>{`${sale.agent} (${formatNumber(parseFloat(sale.agentPercentage) * 100)}%)`}</Name>
                <IconC color={blue[600]}>sell</IconC>
                <Name>{formatNumber(sale.netPricePortion)}</Name>
            </GridFlexBox>
        </>
    );
}
