import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { GridContainer, GridFlexBox, Name, Title } from "../themes/themes";

function ChatGPTPage() {
    const [inputText, setInputText] = useState("");
    const [response, setResponse] = useState("");
    const [sources, setSources] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const res = await fetch(
                `https://asia-southeast1-simply-nice-app.cloudfunctions.net/hello_world?input_text=${encodeURIComponent(
                    inputText
                )}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );

            const data = await res.json();
            setResponse(data.response);
            setSources(data.sources);
            setLoading(false);
        } catch (error) {
            console.error("Error:", error);
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="md">
            <GridContainer>
                <GridFlexBox>
                    <Title>Custom Knowledge ChatGPT </Title>
                </GridFlexBox>
                <GridFlexBox>
                    <Name>(Demo version)</Name>
                </GridFlexBox>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 4,
                        width: "100%"
                    }}
                >
                    <TextField
                        label="Enter your question here"
                        variant="outlined"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                        multiline
                    />
                    <Button variant="contained" onClick={handleSubmit} disabled={loading}>
                        Submit
                    </Button>
                    {loading && (
                        <Box sx={{ mt: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {!loading && (
                        <>
                            <Typography variant="h6" sx={{ mt: 4 }}>
                                Response:
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1, mb: 2, whiteSpace: "pre-wrap" }}>
                                {response}
                            </Typography>
                            <Typography variant="h6">Sources:</Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                {sources}
                            </Typography>
                        </>
                    )}
                </Box>
            </GridContainer>
        </Container>
    );
}

export default ChatGPTPage;
