import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import "animate.css";

const animationsEntrance = [
    "animate__fadeIn"
    // "animate__bounce",
    // "animate__flash",
    // "animate__pulse",
    // "animate__rubberBand",
    // "animate__shakeX",
    // "animate__shakeX",
    // "animate__swing",
    // "animate__tada",
    // "animate__wobble",
    // "animate__jello",
    // "animate__heartBeat",
    // "animate__bounceIn",
    // "animate__bounceInDown",
    // "animate__bounceInLeft",
    // "animate__bounceInRight",
    // "animate__bounceInUp",
    // "animate__lightSpeedInRight",
    // "animate__rotateIn",
];

const animationsExit = [
    "animate__fadeOut"
    // "animate__backInDown",
    // "animate__backOutLeft",
    // "animate__backOutRight",
    // "animate__backOutUp",
    // "animate__bounceOut",
    // "animate__bounceOutDown",
    // "animate__bounceOutLeft",
    // "animate__bounceOutRight",
    // "animate__bounceOutUp",
    // "animate__rotateOut",
];

export function notification(title, message, type, duration = 4000) {
    const entrance =
        animationsEntrance[
            Math.floor(Math.random() * animationsEntrance.length)
        ];
    const exit =
        animationsExit[Math.floor(Math.random() * animationsExit.length)];
    Store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", `${entrance}`],
        animationOut: ["animate__animated", `${exit}`],
        dismiss: {
            duration: duration,
            onScreen: false,
            showIcon: true
        },
        isMobile: true,
        pauseOnHover: true
    });
}
