import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function populateInvolveInSales(users, sales) {
    try {
        //trial from 0 to 10
        const start = 0;
        const end = sales.length;

        sales.slice(start, end).forEach(async (sale) => {
            let involve = [];
            let involveId = [];
            const findLeader = (agentName) => {
                const user = users.find((doc) => doc.agent === agentName);
                if (user) {
                    involve.push(user.agent);
                    involveId.push(user.id);
                    findLeader(user.directLeader);
                } else {
                    return;
                }
            };
            findLeader(sale.agent);
            const docRef = doc(db, "sales", sale.id);
            await setDoc(
                docRef,
                {
                    ...sale,
                    involve: involve,
                    involveId: involveId
                },
                { merge: true }
            );
            console.log(sale.id, " updated");
        });
    } catch (error) {
        console.log(error);
    }
}
