import convertDate from "./convertDate";
import formatDate from "./formatDate";

export default function getDayMonthYear(date) {
    let d = convertDate(date);
    d = formatDate(date);
    let day = d.split("-")[2];
    let month = d.split("-")[1];
    let year = d.split("-")[0];

    return { day, month, year };
}
