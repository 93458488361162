import {
    Box,
    Container,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SaveNoteComponent from "../../components/Notes/SaveNoteComponent";
import ReactMarkdownComponent from "../../components/ReactMarkdown/ReactMarkdownComponent";
import { AuthContext } from "../../context/AuthContext";
import loadNotes from "../../functions/notes/loadNotes";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import AddIcon from "@mui/icons-material/Add";

export default function TrainingPage() {
    const [text, setText] = useState("");
    const [note, setNote] = useState({});
    const [notes, setNotes] = useState([]);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const loadData = async () => {
            const notes = await loadNotes(user.id);
            setNotes(notes);
        };
        loadData();
    }, []);

    const handleChangeText = (e) => {
        setText(e.target.value);
    };

    const handleClose = (note) => {
        console.log(note);
        setNote(note);
        setText(note.markdown);
    };

    const handleClickNote = (index) => {
        const note = notes[index];
        setNote(note);
        setText(note.markdown);
    };

    const updateDelete = (note) => {
        const index = notes.findIndex((n) => n.id === note.id);
        if (index > -1) {
            const newNotes = [...notes];
            newNotes.splice(index, 1);
            setNotes(newNotes);
        }
    };

    const addNewNote = () => {
        setText("");
        setNote({});
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Agents Training Ground</Title>
                </GridFlexBox>
                <GridDivider />
                <Grid container display={"flex"} flexDirection={"row"}>
                    <Grid item display={"flex"} xs={12} md={6}>
                        <Box sx={{ width: "100%", height: "80vh", overflowY: "scroll" }} m={"8px"} p={"8px"}>
                            <ReactMarkdownComponent markdownText={text} />
                        </Box>
                    </Grid>
                    <Grid item display={"flex"} xs={12} md={6}>
                        <Box sx={{ width: "100%", height: "60vh" }} m={"8px"}>
                            <GridFlexBox sx={{ border: "1px solid lightgrey", margin: "8px" }} fs>
                                <ListItemButton onClick={() => addNewNote()}>
                                    <AddIcon />
                                    <Name>Add note</Name>
                                </ListItemButton>
                            </GridFlexBox>
                            <GridFlexBox>
                                <Box sx={{ height: "40vh", overflowY: "scroll", width: "100%", margin: "8px" }}>
                                    <List dense>
                                        {notes.map((note, index) => (
                                            <ListItem key={note.id} disablePadding>
                                                <ListItemButton onClick={() => handleClickNote(index)}>
                                                    <ListItemText primary={note.title} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </GridFlexBox>
                            <GridFlexBox>
                                <TextField
                                    multiline
                                    sx={{ width: "100%" }}
                                    rows={11}
                                    variant="outlined"
                                    value={text}
                                    onChange={handleChangeText}
                                    placeholder="your text here..."
                                />
                            </GridFlexBox>
                            <GridFlexBox fs sx={{ marginTop: "8px" }}>
                                <SaveNoteComponent
                                    handleClose={handleClose}
                                    note={{ ...note, markdown: text }}
                                    updateDelete={updateDelete}
                                />
                            </GridFlexBox>
                        </Box>
                    </Grid>
                </Grid>
            </GridContainer>
        </Container>
    );
}
