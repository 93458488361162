import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authUser, setAuthUser] = useState();

    useEffect(() => {
        // Local storage persistence for user object
        const userLocal = JSON.parse(localStorage.getItem("user"));
        console.log("userLocal: ", userLocal);
        if (userLocal) {
            setUser(userLocal);
        }

        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, "users", user.email);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists) {
                    const fbUser = { ...userSnap.data(), id: userSnap.id };
                    setUser(fbUser);
                } else {
                    notification(
                        "Opps",
                        "You do not have an account in the system. Please check with your admin",
                        "warning"
                    );
                    clearLocalUser(setUser);
                    return;
                }
            } else {
                clearLocalUser(setUser);
            }
        });
    }, [authUser]);

    return (
        <AuthContext.Provider
            value={{
                user,
                authUser,
                setUser,
                setAuthUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function clearLocalUser(setUser) {
    localStorage.removeItem("user");
    localStorage.removeItem("authUser");
    setUser();
    console.log("Not signed in");
}
