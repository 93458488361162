import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function loadSale(user, saleId) {
    try {
        const docRef = doc(db, "sales", saleId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists) {
            const sale = { ...docSnap.data(), id: docSnap.id };
            if (sale.involveId.includes(user.id)) {
                console.log(sale, "loaded");
                return sale;
            } else {
                notification(
                    "Opps",
                    "You do not have permission to load this data",
                    "danger"
                );
                return null;
            }
        } else return null;
    } catch (error) {
        console.log(error);
    }
}
