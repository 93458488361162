import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { COPYRIGHT_LINK, WEBSITE_NAME } from "../settings/settings";
import { notification } from "../utils-functions/notification";
import signUp from "../functions/signUp";
import { AuthContext } from "../context/AuthContext";
import { useState } from "react";
import { LinearProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href={COPYRIGHT_LINK}>
                {WEBSITE_NAME}
            </Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function SignUp() {
    const { setUser, setAuthUser } = React.useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { userId } = useParams();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get("email"),
            password: data.get("password"),
            password2: data.get("password2")
        });
        const firstName = data.get("firstName");
        const lastName = data.get("lastName");
        const email = data.get("email");
        const password = data.get("password");
        const password2 = data.get("password2");
        const secretCode = data.get("secretCode");

        if (secretCode !== "eliteone123") {
            notification("Opps", "Your secret code is incorrect", "warning");
            return;
        }

        if (password !== password2) {
            notification(
                "Passwords not match",
                "Please make sure both passwords are the same",
                "warning"
            );
        } else {
            setLoading(true);
            const user = await signUp(
                firstName,
                lastName,
                email.toLowerCase(),
                password,
                setUser,
                setAuthUser
            );
            setLoading(false);
            if (user) navigate("/");
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                gap={1}
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                {userId && (
                    <Typography fontSize="small">
                        {`Dear ${userId}.
                        Due to a recent migration to Eliteone App v3, you have
                        to SIGN UP again using your existing email.`}
                    </Typography>
                )}
                <Box
                    component="form"
                    Validate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                type="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password2"
                                label="Reenter Password"
                                type="password"
                                id="password2"
                                autoComplete="new-password"
                            />
                            <Grid item xs={12}>
                                {" "}
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="secretCode"
                                    label="Secret Code"
                                    type="text"
                                    id="secretCode"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {loading && <LinearProgress />}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/sign-in" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}
