import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default function fixHan(sales, users) {
    let s = sales.filter((a) => a.agent === "Han");
    console.log(s);
    try {
        s.forEach(async (sale) => {
            let involve = [];
            let involveId = [];
            getInvolve(users, sale.agent, involve, involveId);
            console.log(involveId);

            const docRef = doc(db, "sales", sale.id);
            const newSale = { ...sale, involve, involveId };
            await setDoc(docRef, newSale, { merge: true });
            console.log(sale.id, " updated");
        });
    } catch (err) {
        console.log(err);
    }
}

function getInvolve(users, agent, involve, involveId) {
    let user = users.find((u) => u.agent === agent);
    if (user) {
        involve.push(user.agent);
        involveId.push(user.id);
        getInvolve(users, user.directLeader, involve, involveId);
    } else {
        if (agent === "Aaron Siow") return;
        console.log("Cannot find ", agent);
    }
}
