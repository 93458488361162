import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export const populateNumberOfInvolve = (users, sales) => {
    let numberOfInvolveObj = {};
    users.forEach((user) => {
        let numberOfInvolve = 0;
        sales.forEach((sale) => {
            if (sale.involveId.includes(user.id)) {
                numberOfInvolve++;
            }
        });
        numberOfInvolveObj[user.id] = numberOfInvolve;
    });

    const array = Object.keys(numberOfInvolveObj).map((key) => {
        return { id: key, numberOfInvolve: numberOfInvolveObj[key] };
    });

    array.sort((a, b) => {
        return b.numberOfInvolve - a.numberOfInvolve;
    });

    console.log(array);

    users.forEach(async (user) => {
        const docRef = doc(db, "users", user.id);
        await setDoc(
            docRef,
            { ...user, numberOfInvolve: numberOfInvolveObj[user.id] },
            { merge: true }
        );
        console.log(user.id, " updated in Database");
    });

    return array;
};
