import { Button, Divider, Grid, Icon, LinearProgress, styled, TextField, Typography } from "@mui/material";
import { blue, orange } from "@mui/material/colors";
import { Box } from "@mui/system";

export const GridContainer = (props) => {
    const { s1, s2, s3, mt16, spacing, padding = 0 } = props;
    let s = 1;
    s = s1 ? 1 : s;
    s = s2 ? 2 : s;
    s = s3 ? 3 : s;

    if (spacing) s = spacing;

    let mt;
    if (mt16) mt = "16px";

    return (
        <Grid
            container
            spacing={s}
            justifyContent="center"
            alignItems="center"
            sx={{ padding: padding, marginTop: { xs: mt, sm: mt } }}
        >
            {props.children}
        </Grid>
    );
};

export const GridFlexBox = ({
    justifyContent,
    xs,
    md,
    fs,
    fe,
    gap = 1,
    m0,
    p0,
    p2,
    p5,
    w = "100%",
    overflow,
    fd,
    children,
    sx,
    onClick = () => {}
}) => {
    if (fs) justifyContent = "flex-start";
    if (fe) justifyContent = "flex-end";
    let margin;
    let padding;
    if (m0) margin = 0;
    if (p0) padding = 0;
    if (p2) padding = 2;
    if (p5) padding = 5;
    let whiteSpace = "nowrap";
    if (overflow) whiteSpace = "normal";

    return (
        <Grid
            item
            xs={xs}
            md={md}
            style={{
                paddingTop: padding,
                width: w ? w : "auto",
                whiteSpace: whiteSpace
            }}
        >
            <Box
                display="flex"
                flexDirection={fd}
                justifyContent={justifyContent ? justifyContent : "center"}
                alignItems="center"
                gap={gap}
                onClick={onClick}
                style={{
                    margin: margin,
                    paddingTop: padding,
                    paddingBottom: padding
                }}
                sx={sx}
            >
                {children}
            </Box>
        </Grid>
    );
};

export const Title = (props) => {
    return <Typography variant="h5">{props.children}</Typography>;
};

export const Name = (props) => {
    let {
        bold,
        ct,
        fs10,
        fs12,
        fs13,
        fs14,
        fs20,
        color,
        wrap,
        nowrap = true,
        mr = 0,
        ml1,
        ml3,
        ml5,
        onClick = () => {},
        cp,
        whiteSpace = "normal",
        pt1,
        pb1,
        pl1,
        pr1,
        bgcolor
    } = props;

    // Font Size
    let fontSize;
    if (ct) fontSize = "0.8rem";
    if (fs10) fontSize = 10;
    if (fs12) fontSize = "12px";
    if (fs13) fontSize = 13;
    if (fs14) fontSize = "0.9rem";
    if (fs20) fontSize = 20;

    let fontWeight;
    if (bold) fontWeight = "bold";

    let noWrap = true;
    if (wrap) {
        whiteSpace = "normal";
        noWrap = false;
    }
    if (nowrap) {
        whiteSpace = "nowrap";
        noWrap = true;
    }

    let marginLeft = 0;
    if (ml1) marginLeft = 1;
    if (ml3) marginLeft = 3;
    if (ml5) marginLeft = 5;

    let paddingTop = 0;
    let paddingBottom = 0;
    let paddingLeft = 0;
    let paddingRight = 0;
    if (pt1) paddingTop = 1;
    if (pb1) paddingBottom = 1;
    if (pl1) paddingLeft = 1;
    if (pr1) paddingRight = 1;

    let cursor;
    if (cp) cursor = "pointer";

    return (
        <Typography
            variant="body2"
            noWrap={noWrap}
            marginRight={mr}
            marginLeft={marginLeft}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            fontWeight={fontWeight}
            onClick={onClick}
            style={{
                fontSize: fontSize,
                color: color,
                whiteSpace: whiteSpace,
                overflowWrap: "break-word",
                cursor: cursor,
                backgroundColor: bgcolor
            }}
        >
            {props.children}
        </Typography>
    );
};

export const GridDivider = (props) => {
    const { ptb } = props;

    return (
        <Grid
            item
            xs={12}
            style={{
                paddingTop: ptb,
                paddingBottom: ptb
            }}
        >
            <Divider />
        </Grid>
    );
};

export const IconC = (props) => {
    let { color, onClick, cp } = props;
    let cursor;
    if (cp) cursor = "pointer";
    return (
        <Icon fontSize="small" color={"primary"} style={{ color: color, cursor: cursor }} onClick={onClick}>
            {props.children ? props.children : "account_circle"}
        </Icon>
    );
};

export const Loading = ({ loading }) => {
    return (
        <Grid item xs={12}>
            {loading && <LinearProgress />}
        </Grid>
    );
};

export const InputC = styled(TextField)({});

export const TextFieldC = ({ ph, onChange = () => {}, id, type, value }) => {
    return (
        <TextField
            size="small"
            placeholder={ph}
            onChange={onChange}
            id={id}
            type={type}
            value={value}
            inputProps={{
                style: {
                    padding: 2,
                    fontSize: 14
                }
            }}
        />
    );
};

export const ButtonC = (props) => {
    const { bgColor, small, p0, fs10, ml1 } = props;

    let padding;
    if (p0) padding = 0;
    let size;
    if (small) size = "small";

    let marginLeft = 0;
    if (ml1) marginLeft = "8px";

    let fontSize;
    if (fs10) fontSize = 10;
    return (
        <Button
            size={size}
            variant="contained"
            onClick={props.onClick}
            style={{
                backgroundColor: bgColor,
                paddingTop: padding,
                paddingBottom: padding,
                marginLeft: marginLeft,
                fontSize: fontSize
            }}
        >
            {props.children}
        </Button>
    );
};

export const ChartC = (props) => {
    return (
        <Typography
            noWrap
            fontSize={"small"}
            style={{
                marginLeft: -2,
                color: blue[500],
                fontSize: "10px"
            }}
        >
            {props.children}
        </Typography>
    );
};

export const Status = styled("button")(({ clicked, bgcolor = orange[700] }) => {
    if (!clicked) bgcolor = "lightgray";
    return {
        border: "solid 1px",
        borderRadius: "7px",
        color: "white",
        borderColor: bgcolor,
        backgroundColor: bgcolor,
        fontSize: "12px",
        paddingLeft: "10px",
        paddingRight: "10px",
        cursor: "pointer"
    };
});

export const GridItem = (props) => {
    const { fe, fs, w = "100%", fs10, fs12, fs14, fs20, bold, blueColor, mt2, mt4, pl1 } = props;

    let color;
    if (blueColor) color = blue[700];
    return (
        <GridFlexBox fe={fe} fs={fs} w={w} mt2={mt2} mt4={mt4} pl1={pl1}>
            <Name fs10={fs10} fs12={fs12} fs14={fs14} fs20={fs20} bold={bold} color={color}>
                {props.children}
            </Name>
        </GridFlexBox>
    );
};
