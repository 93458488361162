import { useTheme } from "@emotion/react";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight, vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";

export default function ReactMarkdownComponent({ markdownText }) {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                        <CodeBlock language={match[1]} value={String(children).replace(/\n$/, "")} {...props} />
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    );
                }
            }}
            sx={{ whiteSpace: "pre-wrap" }}
        >
            {markdownText}
        </ReactMarkdown>
    );
}

const CodeBlock = ({ language, value }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;

    return (
        <SyntaxHighlighter language={language} style={mode === "light" ? oneLight : vscDarkPlus}>
            {value}
        </SyntaxHighlighter>
    );
};
