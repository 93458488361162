import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function updateSale(state, members, user, saleId) {
    try {
        const data = {
            ...state,
            date: new Date(state.date),
            members: members,
            dateUpdated: new Date(),
            updatedBy: { displayName: user.displayName, id: user.id }
        };
        const docRef = doc(db, "sales", saleId);
        await setDoc(docRef, data, { merge: true });
        console.log("Updated sale in Database: ", docRef.id);
        notification("Success", "Sale updated in Database", "success");
        return data;
    } catch (err) {
        console.log(err);
    }
}
