import React, { useState } from "react";

export const StoreContext = React.createContext();

export const StoreProvider = ({ children }) => {
    const [store, setStore] = useState({});

    const [unsubscribeStore, setUnsubscribeStore] = useState();

    const addUnsubscribeStore = (fn) => {
        if (unsubscribeStore) {
            let array = [...unsubscribeStore];
            array.push(fn);
            setUnsubscribeStore(array);
        } else {
            setUnsubscribeStore([fn]);
        }
    };

    const clearStore = () => {
        setStore();
        if (unsubscribeStore) {
            unsubscribeStore.forEach((fn) => fn());
        }
    };

    return (
        <StoreContext.Provider
            value={{
                store,
                setStore,
                unsubscribeStore,
                setUnsubscribeStore,
                clearStore,
                addUnsubscribeStore
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export const useGetFriends = () => {
    const { getFriends } = React.useContext(StoreContext);
    return getFriends;
};

export function useGetFolders() {
    const { getFolders } = React.useContext(StoreContext);
    return getFolders;
}

export const useGetLabels = () => {
    const { getLabels } = React.useContext(StoreContext);
    return getLabels;
};
