import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import loadUser from "./loadUser";

export async function signIn(state, setUser, setAuthUser) {
    try {
        const user = await loadUser(state.email.toLowerCase());
        if (!user) {
            notification(
                "Opps",
                "You do not have an account in the system. Please check with your admin",
                "warning"
            );
            return null;
        }
        if (!user.displayName) {
            return user.agent;
        }

        const result = await signInWithEmailAndPassword(
            auth,
            state.email.toLowerCase(),
            state.password
        );

        const authUser = result.user;

        setUser(user);
        setAuthUser(authUser);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("authUser", JSON.stringify(authUser));
        console.log(user.email);
        notification("Signed in", "Signed in successfully", "success");
        return "user";
    } catch (err) {
        let message = err.message;

        if (err.code === "auth/user-not-found") {
            message = "Please check if you have entered the correct email";
        }

        if (err.code === "auth/wrong-password") {
            message = "Please check if you have entered the correct password";
        }

        notification("Opps", message, "danger");
        console.log(err);
    }
}
