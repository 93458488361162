import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import convertDate from "../utils-functions/convertDate";

export default function mapSalesToCampaigns(sales, campaigns) {
    if (!sales) return [];
    let newCampaigns = [...campaigns];
    newCampaigns.forEach((campaign) => {
        campaign.sales = [];
        sales.forEach((sale) => {
            if (
                campaign.projects.includes(sale.project) &&
                convertDate(sale.bookingDate) >= campaign.startDate &&
                convertDate(sale.bookingDate) <= campaign.endDate &&
                sale.status !== "CANCELLED"
            ) {
                const newSale = { ...sale };
                newSale.multiplier = checkMultiplier(campaign, sale);
                campaign.sales.push(newSale);
            }
        });
        campaign.agents = {};
        campaign.sales.forEach((sale) => {
            campaign.agents[sale.agent] = campaign.agents[sale.agent] ? [...campaign.agents[sale.agent], sale] : [sale];
        });

        campaign.agentsTotal = {};
        Object.keys(campaign.agents).forEach((agent) => {
            const totalBooked = campaign.agents[agent].reduce((acc, curr) => {
                if (curr.status === "BOOKED") {
                    return acc + curr.netPricePortion * checkMultiplier(campaign, curr);
                } else {
                    return acc;
                }
            }, 0);
            const totalBookedNumber = campaign.agents[agent].reduce((acc, curr) => {
                if (curr.status === "BOOKED") {
                    return acc + curr.agentPercentage * checkMultiplier(campaign, curr);
                } else {
                    return acc;
                }
            }, 0);
            const totalConverted = campaign.agents[agent].reduce((acc, curr) => {
                if (curr.status === "BILLED" || curr.status === "TO-INVOICE") {
                    return acc + curr.netPricePortion * checkMultiplier(campaign, curr);
                } else {
                    return acc;
                }
            }, 0);
            const totalConvertedNumber = campaign.agents[agent].reduce((acc, curr) => {
                if (curr.status === "BILLED" || curr.status === "TO-INVOICE") {
                    return acc + curr.agentPercentage * checkMultiplier(campaign, curr);
                } else {
                    return acc;
                }
            }, 0);
            const totalConvertedOntime = campaign.agents[agent].reduce((acc, curr) => {
                if (
                    (curr.status === "BILLED" || curr.status === "TO-INVOICE") &&
                    isOnTime(curr.spaSignedDate, curr.bookingDate, campaign.conversion, campaign.conversionDate)
                ) {
                    return acc + curr.netPricePortion * checkMultiplier(campaign, curr);
                } else {
                    return acc;
                }
            }, 0);
            const totalConvertedNumberOntime = campaign.agents[agent].reduce((acc, curr) => {
                if (
                    (curr.status === "BILLED" || curr.status === "TO-INVOICE") &&
                    isOnTime(curr.spaSignedDate, curr.bookingDate, campaign.conversion)
                ) {
                    return acc + curr.agentPercentage * checkMultiplier(campaign, curr);
                } else {
                    return acc;
                }
            }, 0);
            campaign.agentsTotal[agent] = {
                totalBooked,
                totalConverted,
                totalBookedNumber,
                totalConvertedNumber,
                totalConvertedOntime,
                totalConvertedNumberOntime
            };
        });

        let agentsArray = [];
        Object.keys(campaign.agentsTotal).forEach((key) => {
            agentsArray.push({
                name: key,
                totalBooked: campaign.agentsTotal[key].totalBooked,
                totalBookedNumber: campaign.agentsTotal[key].totalBookedNumber,
                totalConverted: campaign.agentsTotal[key].totalConverted,
                totalConvertedNumber: campaign.agentsTotal[key].totalConvertedNumber,
                sales: campaign.agents[key],
                totalConvertedOntime: campaign.agentsTotal[key].totalConvertedOntime,
                totalConvertedNumberOntime: campaign.agentsTotal[key].totalConvertedNumberOntime
            });
        });
        if (campaign.number) {
            agentsArray.sort((a, b) => b.totalBookedNumber - a.totalBookedNumber);
            agentsArray.sort((a, b) => b.totalConvertedNumber - a.totalConvertedNumber);
        } else {
            agentsArray.sort((a, b) => b.totalBooked - a.totalBooked);
            agentsArray.sort((a, b) => b.totalConverted - a.totalConverted);
        }

        campaign.agentsArray = agentsArray;
    });
    console.log(newCampaigns);
    return newCampaigns;
}

const checkMultiplier = (campaign, sale) => {
    const specialCampaigns = campaign.special.filter((doc) => doc.project === sale.project);
    let multiplier = 1;
    if (!arrayIsEmpty(specialCampaigns)) {
        specialCampaigns.some((doc) => {
            if (doc.startDate && doc.endDate && doc.conversion) {
                // Ensure startDate is at the first second of the day
                const startDate = convertDate(doc.startDate);
                const endDate = convertDate(doc.endDate);
                startDate.setHours(0, 0, 1, 0); // 00:00:01
                // Ensure endDate is at the last second of the day
                endDate.setHours(23, 59, 59, 999); // 23:59:59.999

                if (convertDate(sale.bookingDate) >= startDate && convertDate(sale.bookingDate) <= endDate) {
                    const bookingDatePlusConversion = new Date(
                        convertDate(sale.bookingDate).getTime() + doc.conversion * 24 * 60 * 60 * 1000
                    );
                    if (convertDate(sale.spaSignedDate) <= bookingDatePlusConversion) {
                        multiplier = doc.multiplier;
                        return true;
                    }
                }
            }
        });
    }
    return multiplier;
};

function isOnTime(spaSignedDate, bookingDate, conversion, conversionDate) {
    if (!conversionDate) {
        const bookingDatePlusConversion = new Date(
            convertDate(bookingDate).getTime() + conversion * 24 * 60 * 60 * 1000
        );
        return convertDate(spaSignedDate) <= bookingDatePlusConversion;
    } else {
        return convertDate(spaSignedDate) <= convertDate(conversionDate);
    }
}
