import { notification } from "../utils-functions/notification";
import loadUser from "./loadUser";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function sendSignInLink(email) {
    try {
        //Check to see whether the email is already in the system
        const user = await loadUser(email.toLowerCase());
        if (!user) {
            notification(
                "Opps",
                "You do not have an account with the system. Please check with your admin",
                "danger"
            );
            return;
        }

        const url = window.location.href + "/login";
        const collectionRef = collection(db, "emailLogins");
        await addDoc(collectionRef, { ...user, loginUrl: url });
        console.log("emailLogin added to Firebase");

        window.localStorage.setItem("emailForSignIn", email);
        return true;
    } catch (error) {
        console.log(error);
        notification("Opps", error.message, "error");
        return false;
    }
}
