import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import sendEmailVerificationLink from "../functions/sendEmailVerificationLink";
import {
    GridContainer,
    GridFlexBox,
    Loading,
    Name,
    Title
} from "../themes/themes";

export default function VerifyEmailPage() {
    const { user } = useContext(AuthContext);
    const [sent, setSent] = useState();
    const [loading, setLoading] = useState(false);

    const sendLink = async () => {
        setLoading(true);
        const response = await sendEmailVerificationLink(user);
        setSent(response);
        setLoading(false);
    };

    return (
        <Container maxWidth="sm">
            <GridContainer s3>
                <GridFlexBox>
                    <Title>Email verification</Title>
                </GridFlexBox>
                <GridFlexBox>
                    <Name>Please verify your email</Name>
                    <Name bold>{user.email}</Name>
                </GridFlexBox>
                <GridFlexBox>
                    <Name>
                        {sent &&
                            "Please check your email inbox for the email verification link"}
                    </Name>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridFlexBox>
                    <Button
                        variant="contained"
                        onClick={sendLink}
                        disabled={sent}
                    >
                        Send Email Verification Link
                    </Button>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
