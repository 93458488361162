import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";

export default async function getProjects(sales) {
    try {
        const projectsRef = collection(db, "projects");
        const projectsSnap = await getDocs(projectsRef);
        let extProjects = [];
        if (!projectsSnap.empty) {
            extProjects = projectsSnap.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id
            }));
        }

        let projects = {};
        let array = [];
        sales.forEach((sale) => {
            if (!sale.project) return;
            const projectId = `${sale.project} - ${sale.unitNo}`;
            const f = array.find((doc) => doc === projectId);
            if (f) return;
            array.push(projectId);
            projects[sale.project] = {
                name: sale.project,
                count: projects[sale.project] ? projects[sale.project].count + 1 : 1
            };
        });
        const projectsArray = Object.keys(projects).map((key) => {
            const found = extProjects.find((doc) => doc.id === key);
            let commRate = [{ date: new Date("2014-01-01"), ren: "" }];
            let developer = "";
            if (found) {
                if (!found.commRate) {
                    commRate = [
                        {
                            date: new Date("2014-01-01"),
                            ren: found.commission
                        }
                    ];
                } else {
                    if (!arrayIsEmpty(found.commRate)) {
                        commRate = found.commRate;
                        commRate.sort((a, b) => b.date - a.date);
                    }
                }
                developer = found.developer || "";
            }
            return {
                name: key,
                count: projects[key]["count"],
                commRate: commRate,
                developer
            };
        });
        projectsArray.sort((a, b) => b.count - a.count);
        console.log(projectsArray);
        return projectsArray;
    } catch (error) {
        console.log(error);
    }
}
