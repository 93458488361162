import { Button, Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SaleDetails from "../components/SaleDetails";
import { AuthContext } from "../context/AuthContext";
import { SalesContext } from "../context/SalesContext";
import loadSale from "../functions/loadSale";
import { Loading } from "../themes/themes";

export default function SaleCardPage() {
    const { sales } = useContext(SalesContext);
    const { user } = useContext(AuthContext);

    const [sale, setSale] = useState();
    const [otherSales, setOtherSales] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { saleId } = useParams();

    useEffect(() => {
        const loadData = async () => {
            if (sales) {
                const sale = sales.find((doc) => doc.id === saleId);
                setSale(sale);
                const otherSales = sales.filter(
                    (doc) => doc.unitId === sale.unitId
                );
                console.log(otherSales);
                setOtherSales(otherSales);
            } else {
                setLoading(true);
                const sale = await loadSale(user, saleId);
                if (sale) setSale(sale);
                setLoading(false);
            }
        };
        loadData();
    }, [sales]);

    const editSale = () => {
        navigate(`/add-sale/${sale.id}`);
    };

    return (
        <Container maxWidth={"sm"}>
            <Box>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Loading loading={loading} />
                    {sale && (
                        <SaleDetails
                            handleOpen={null}
                            sale={sale}
                            sales={otherSales}
                        />
                    )}

                    <Grid item xs={12}>
                        <Box display={"flex"}>
                            <Button
                                size="small"
                                color="warning"
                                variant="contained"
                                style={{ margin: "3px" }}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                size="small"
                                color="info"
                                variant="contained"
                                onClick={editSale}
                                style={{ margin: "3px" }}
                                disabled
                            >
                                Edit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
