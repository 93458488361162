export const headerName = [
    "unitId",
    "project",
    "location",
    "unitNo",
    "status",
    "bookingDate",
    "team",
    "agent",
    "branch",
    "agentPercentage",
    "spaPricePortion",
    "netPricePortion",
    "spaPrice",
    "netPrice",
    "earnDeposit",
    "earnDepositPaid",
    "spaSignedDate",
    "signedLOReceivedDate",
    "summaryStatus",
    "age",
    "agentJoinedDate",
    "directLeader",
    "agentTitle",
    "agentLastUpdate",
    "buyerName",
    "discountRemarks",
    "agentEmail"
];







