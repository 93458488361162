import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

export default function FormDialog({ open, setOpen, title, text, label, onSave, initialValue }) {
    const [value, setValue] = useState(initialValue);

    const handleCloseCancel = () => {
        setOpen(false);
    };
    
    const handleCloseSave = () => {
        onSave(value);
        setValue("");
        setOpen(false);
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <div>
            <Dialog open={open} onClose={handleCloseSave} fullWidth PaperProps={{ sx: { position: "fixed", top: 10 } }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{text}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={label}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        multiline
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCloseSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
