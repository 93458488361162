import { removeDuplicate } from "../utils-functions/removeDuplicate";

export default function findMissingUplineInSales(sales){
    let n = [];
    sales.forEach((sale) => {
        const f = sale.involveId.find((id) => id === "daveckw@gmail.com");
        if (!f) {
            n.push(sale.id);
        }
    });
    n = removeDuplicate(n);
    console.log(n);
}