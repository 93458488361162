import {
    Avatar,
    Button,
    Container,
    Divider,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ListContext } from "../context/ListContext";
import { SalesContext } from "../context/SalesContext";
import { UsersContext } from "../context/UsersContext";
import addSale from "../functions/addSale";
import formatDate from "../utils-functions/formatDate";
import convertDate from "../utils-functions/convertDate";
import loadSales from "../functions/loadSales";
import loadUsers from "../functions/loadUsers";
import updateSale from "../functions/updateSale";
import updateState from "../functions/updateState";

const MEMBER = { displayName: "", percentage: "", role: "", id: "" };
const STATE = {
    date: formatDate(new Date()),
    clientName: "",
    propertyName: "",
    unitNumber: "",
    saleAmount: "",
    remark: "",
    profit: 0
};

export default function AddSale() {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState([{ ...MEMBER }]); //Must use spread operator for Object inside array
    const [state, setState] = useState(STATE);

    const { openGlobalList } = useContext(ListContext);
    const { users, setUsers } = useContext(UsersContext);
    const { user } = useContext(AuthContext);
    const { sales, setSales } = useContext(SalesContext);

    const { saleId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            if (saleId) {
                if (sales) {
                    const sale = sales.find((doc) => doc.id === saleId);
                    if (sale) {
                        const { members, ...others } = sale;
                        setState({
                            ...others,
                            date: formatDate(convertDate(sale.date))
                        });
                        setMembers(members);
                    }
                } else {
                    const sales = await loadSales(user);
                    if (sales) setSales(sales);
                }
            }
        };
        loadData();
    }, [sales]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        setLoading(true);
        if (!saleId) {
            await addSale(state, members, user);
            setState(STATE);
        } else {
            const updatedSale = await updateSale(state, members, user, saleId);
            updateState(updatedSale, sales, setSales);
        }
        setLoading(false);
    };

    const addMember = () => {
        const newMembers = [...members, { ...MEMBER }]; //Must use Spread operator for deep copy
        setMembers(newMembers);
        console.log(newMembers);
    };

    const removeMember = (i) => {
        if (i === 0) return;

        const newMembers = [...members];
        console.log(i);
        newMembers.splice(i, 1);
        setMembers(newMembers);
    };

    const clickMember = async (i) => {
        let usersList = users;
        if (!users) {
            setLoading(true);
            usersList = await loadUsers();
            setLoading(false);
            if (usersList) {
                setUsers(usersList);
            }
        }
        const response = await openGlobalList(
            usersList,
            "Select member",
            "displayName",
            "face",
            false,
            true
        );
        if (response) {
            const newMembers = [...members];
            newMembers[i]["displayName"] = response.displayName;
            newMembers[i]["id"] = response.id;
            setMembers(newMembers);
        }
        console.log(response);
    };

    const handleChange = (e) => {
        const { id, name, value } = e.target;
        const newMembers = [...members];
        newMembers[id][name] = value;
        setMembers(newMembers);
    };

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        const newState = { ...state, [name]: value };
        setState(newState);
    };

    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <Icon>attach_money</Icon>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {!saleId ? "Add Sale" : "Edit Sale"}
                </Typography>
                <Box
                    component="form"
                    Validate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="date"
                                required
                                fullWidth
                                id="date"
                                label="Date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                autoFocus
                                value={state.date}
                                onChange={handleChange2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                id="clientName"
                                label="Client Name"
                                name="clientName"
                                autoComplete="family-name"
                                value={state.clientName}
                                onChange={handleChange2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="propertyName"
                                label="Property Name"
                                name="propertyName"
                                type="text"
                                value={state.propertyName}
                                onChange={handleChange2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="unitNumber"
                                label="Unit Number"
                                type="text"
                                id="unitNumber"
                                value={state.unitNumber}
                                onChange={handleChange2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="saleAmount"
                                label="Sale Amount"
                                type="number"
                                id="saleAmount"
                                value={state.saleAmount}
                                onChange={handleChange2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="remark"
                                label="Remarks"
                                type="text"
                                id="remark"
                                value={state.remark}
                                onChange={handleChange2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {saleId && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="profit"
                                    label="Profit"
                                    type="number"
                                    id="profit"
                                    value={state.profit}
                                    onChange={handleChange2}
                                />
                            </Grid>
                        )}
                        {members.map((member, i) => (
                            <React.Fragment key={`member${i}`}>
                                <Grid item xs={8} md={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="member"
                                        label={`Member ${i + 1}`}
                                        type="text"
                                        id={`${i}`}
                                        value={members[i].displayName}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() =>
                                                        clickMember(i)
                                                    }
                                                >
                                                    <Icon
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        face
                                                    </Icon>
                                                </InputAdornment>
                                            ),
                                            onKeyDown: (event) => {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="percentage"
                                        label={`Percentage ${i + 1}`}
                                        type="number"
                                        id={`${i}`}
                                        value={members[i].percentage}
                                        onChange={handleChange}
                                        inputProps={{
                                            min: 0,
                                            max: 100,
                                            step: 0.01
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={8} md={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="role"
                                        label={`Role ${i + 1}`}
                                        type="text"
                                        id={`${i}`}
                                        value={members[i].role}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4} md={2} textAlign="center">
                                    <IconButton onClick={addMember}>
                                        <Icon fontSize="large">add</Icon>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            removeMember(i);
                                        }}
                                    >
                                        <Icon
                                            fontSize="large"
                                            sx={{
                                                display: i ? "block" : "none"
                                            }}
                                        >
                                            remove
                                        </Icon>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                    {loading && <LinearProgress />}
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2 }}
                    >
                        {saleId ? "Update Sale" : "Add"}
                    </Button>
                </Box>
                <Grid container>
                    <Grid item>
                        <Box display={"flex"} justifyContent="flex-start">
                            {saleId && (
                                <Button
                                    size="small"
                                    type="submit"
                                    color="warning"
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2 }}
                                    onClick={() => navigate(-1)}
                                >
                                    Back
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
