import SendIcon from "@mui/icons-material/Send";
import { Box, Container, Divider, Grid, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactMarkdownComponent from "../../components/ReactMarkdown/ReactMarkdownComponent";
import { AuthContext } from "../../context/AuthContext";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";
import chatGPT from "./chatGPT";

const PlaygroundPage = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [model, setModel] = useState("gpt-3.5-turbo");

    const { user } = useContext(AuthContext);

    const ref = useRef(null);

    useEffect(() => {
        ref.current.scrollTo({
            top: ref.current.scrollHeight,
            behavior: "smooth"
        });
    }, [messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!input) return;
        const newMessages = [...messages, { name: user.displayName, content: input, role: "user" }];
        setMessages(newMessages);
        setInput("");
        await chatGPT(input, setMessages, newMessages, model);
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Playground</Title>
                    <Select
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        sx={{ marginLeft: "16px", width: "200px" }}
                    >
                        <MenuItem value={"gpt-3.5-turbo"}>gpt-3.5-turbo</MenuItem>
                        <MenuItem value={"gpt-4"}>gpt-4</MenuItem>
                    </Select>
                </GridFlexBox>
                <GridDivider />
                <Grid container display={"flex"} flexDirection={"column"}>
                    <Grid item width={"100%"}>
                        <Box
                            sx={{ height: "70vh", border: "1px solid lightgrey", width: "100%", overflowY: "scroll" }}
                            ref={ref}
                        >
                            {messages.map((message, i) => (
                                <Box key={i} sx={{ width: "100%", p: "8px" }} justifyContent={"flex-end"}>
                                    <Typography>{message.name}</Typography>
                                    <ReactMarkdownComponent markdownText={message.content} />
                                    <Divider />
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item width={"100%"} display={"flex"} alignItems={"center"}>
                        <form style={{ width: "100%", display: "flex" }} onSubmit={handleSubmit}>
                            <Box mt="8px" flex={11}>
                                <TextField
                                    fullWidth
                                    placeholder="Type your prompt here"
                                    variant="outlined"
                                    value={input}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Box>
                            <Box flex={1} pl={"10px"} pt={"10px"} display={"flex"}>
                                <IconButton type="submit">
                                    <SendIcon fontSize="large" />
                                </IconButton>
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            </GridContainer>
        </Container>
    );
};

export default PlaygroundPage;
