import React, { useState } from "react";
import { useRef } from "react";
import { createContext } from "react";
import FullDialog from "../components/FullDialog";

export const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
    const [title, setTitle] = useState();
    const [open, setOpen] = useState(false);
    const [Component, setComponent] = useState();

    const handleSave = (object) => {
        setOpen(false);
        awaitingPromiseRef.current.resolve(object);
    };

    const awaitingPromiseRef = useRef();

    // const {openFullDialog} = useContext(DialogContext);
    // const response = await openFullDialog(component, title)
    const openFullDialog = async (Component, title) => {
        setTitle(title);
        setOpen(true);
        setComponent(Component);

        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    //Attach extra prop (handleSave) to the Component
    function AddExtraProps(Component, extraProps) {
        if (!Component) return <div></div>;
        return <Component.type {...Component.props} {...extraProps} />;
    }

    return (
        <DialogContext.Provider value={{ openFullDialog }}>
            {children}
            <FullDialog
                open={open}
                handleClose={handleSave}
                title={title}
                Component={AddExtraProps(Component, {
                    handleClose: handleSave
                })}
            />
        </DialogContext.Provider>
    );
};
