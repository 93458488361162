import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "./components/Menu";
import { AuthContext } from "./context/AuthContext";
import AddSale from "./pages/AddSale";
import CampaignsPage from "./pages/CampaignsPage";
import HomePage from "./pages/CommonPages/HomePage";
import ConversionChartPage from "./pages/ConversionChartPage";
import GroupSalesPage from "./pages/GroupSalesPage";
import IncomesChartPage from "./pages/IncomesChartPage";
import LandingPage from "./pages/LandingPage";
import ProjectsChartPage from "./pages/ProjectsChartPage";
import ProjectsPage from "./pages/ProjectsPage";
import SaleCardPage from "./pages/SaleCardPage";
import SalesChartPage from "./pages/SalesChartPage";
import SalesPage from "./pages/SalesPage";
import SignIn from "./pages/SignIn";
import SignInWithEmail from "./pages/SignInWithEmail";
import SignOut from "./pages/SignOut";
import SignUp from "./pages/SignUp";
import UserCardPage from "./pages/UserCardPage";
import UsersPage from "./pages/UsersPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import SeedAgents from "./seeding/SeedAgents";
import SeedIncomes from "./seeding/SeedIncomes";
import SeedSales from "./seeding/SeedSales";
import { PopulateInvolve } from "./tests/PopulateInvolve";
import { PopulateNumberOfInvolvePage } from "./tests/PopulateNumberOfInvolve";
import TestPage from "./tests/TestPage";
import TestPrintPage from "./tests/TestPrintPage";
import { ColorModeContext, useMode } from "./theme";
import ChatGPTPage from "./pages/ChatGPTPage";
import TrainingPage from "./pages/TrainingPage/TrainingPage";
import PlaygroundPage from "./pages/Playground/PlaygroundPage";
import QAPage from "./pages/TrainingPage/QAPage";
import RecentDealsPage from "./pages/RecentDealsPage/RecentDealsPage";
import CalendarPage from "./pages/CalendarPage/CalendarPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";

function App() {
    const { user, authUser } = useContext(AuthContext);
    const [theme, colorMode] = useMode();

    let displayComponent = notSignedInComponent();
    if (user) {
        if (authUser) {
            if (authUser.emailVerified) {
                displayComponent = signedInComponent(user);
            } else {
                displayComponent = verifyEmail(user);
            }
        } else {
            displayComponent = signedInComponent(user);
        }
    } else {
        displayComponent = notSignedInComponent();
    }
    return (
        <React.Fragment>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Menu user={user} />
                    {displayComponent}
                </ThemeProvider>
            </ColorModeContext.Provider>
        </React.Fragment>
    );
}

export default App;

function signedInComponent(user) {
    return (
        <Routes>
            <Route index element={<HomePage />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-out" element={<SignOut />} />
            <Route path="sign-up" element={<SignUp />}>
                <Route path=":userId" element={<SignUp />} />
            </Route>
            <Route path="add-sale" element={<AddSale />}>
                <Route path=":saleId" element={<AddSale />} />
            </Route>
            <Route path="sales-page" element={<SalesPage />} />
            <Route path="conversion-chart-page" element={<ConversionChartPage />} />
            <Route path="group-sales-page" element={<GroupSalesPage />} />
            <Route path="sales-chart-page" element={<SalesChartPage />} />
            <Route path="incomes-chart-page" element={<IncomesChartPage />} />
            <Route path="campaigns-page" element={<CampaignsPage />} />
            <Route path="sale-card-page" element={<SaleCardPage />}>
                <Route path=":saleId" element={<SaleCardPage />}></Route>
            </Route>
            <Route path="users-page" element={<UsersPage />} />
            <Route path="projects-page" element={<ProjectsPage />} />
            <Route path="projects-chart-page" element={<ProjectsChartPage />} />
            <Route path="chat-gpt-page" element={<ChatGPTPage />} />
            <Route path="user-card-page" element={<UserCardPage />}>
                <Route path=":param" element={<UserCardPage />}></Route>
            </Route>
            <Route path="test-print-page" element={<TestPrintPage />} />
            <Route path="test-page" element={<TestPage />} />
            <Route path="seed-sales" element={<SeedSales />} />
            <Route path="seed-incomes" element={<SeedIncomes />} />
            <Route path="populate-involve" element={<PopulateInvolve />} />
            <Route path="seed-agents" element={<SeedAgents />} />
            <Route path="populate-number-of-involve-page" element={<PopulateNumberOfInvolvePage />} />
            <Route path="training-page" element={<TrainingPage />} />
            <Route path="playground-page" element={<PlaygroundPage />} />
            <Route path="recent-deals-page" element={<RecentDealsPage />} />
            <Route path="calendar-page" element={<CalendarPage />} />
            <Route path="qa-page" element={<QAPage />} />
            <Route path="sign-in-with-email" element={<SignInWithEmail />}>
                <Route path=":emailParam" element={<SignInWithEmail />} />
            </Route>

            <Route path="forgot-password-page" element={<ForgotPasswordPage />} />
        </Routes>
    );
}

function notSignedInComponent(user) {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="sign-up" element={<SignUp />}>
                <Route path=":userId" element={<SignUp />} />
            </Route>
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route path="sign-in-with-email" element={<SignInWithEmail />}>
                <Route path=":emailParam" element={<SignInWithEmail />} />
            </Route>
            <Route path="*" element={<LandingPage />} />
            <Route path="forgot-password-page" element={<ForgotPasswordPage />} />
        </Routes>
    );
}

function verifyEmail() {
    return (
        <Routes>
            <Route path="/" element={<VerifyEmailPage />} />
            <Route exact path="/sign-in" element={<SignIn />} />
        </Routes>
    );
}
