export const missingAgentsList = {
    "Gabrielle Liew Lee Yean": "daveckw@gmail.com",
    "Caindy Wong Chooi Teng": "ken.propertyagent@gmail.com",
    "Agnes Goh": "daveckw@gmail.com",
    "Elleean Liew Yem Har": "waynelim.property@gmail.com",
    "Hanis Syafiqah": "linaproperty16@gmail.com",
    "Ginny Koh": "daveckw@gmail.com",
    "Winson Chong Kwang Hao": "guangtkk@gmail.com",
    "Muhammad Shahril Bin Ismail": "linaproperty16@gmail.com",
    "Victor Leong Kok Wai": "waynelim.property@gmail.com",
    "Zairina Binti Baharun": "linaproperty16@gmail.com",
    "Jensen Lee": "meigi0916@gmail.com",
    "Nabilah Nasir": "zarina.property28@yahoo.com",
    "Azizihana": "linaproperty16@gmail.com",
    "Er Chuin Zheng": "kevinong0203@gmail.com",
    "Sean Inn": "joanlimce@gmail.com",
    "Bryan Lok": "daveckw@gmail.com",
    "Ansel Tan": "aseow6@gmail.com",
    "Presley Chong": "waileong.yap@gmail.com",
    "Jacqueline Lim Wey Na": "albert_dta@yahoo.com.sg",
    "Thomas Jew": "albert_dta@yahoo.com.sg",
    "Ray Wong": "limyokehar@yahoo.com",
    "Chan Li Yin": "daveckw@gmail.com",
    "Wei Jern": "aseow6@gmail.com",
    "Carmen Moo": "sheueshiuan96@gmail.com",
    "Sayyed Nawaz": "waynelim.property@gmail.com",
    "Pendy Soo": "limyokehar@yahoo.com",
    "Law Chee Siang": "propertyeddie88@gmail.com",
    "Jacky Lee": "ryanfca07@gmail.com",
    "Mohd Khalil@Mk": "linaproperty16@gmail.com",
    "Ck Ng": "daveckw@gmail.com",
    "Matthew Chee Hong Fatt": "waynelim.property@gmail.com",
    "Yy Lai": "seanteo.prop@gmail.com",
    "Joe Yee Joo Xie": "chuenyuechee@gmail.com",
    "Peter Liew": "victorchuproperties@gmail.com",
    "Carrie Chu": "victorchuproperties@gmail.com",
    "Dexter Chin Win Zhing": "limyokehar@yahoo.com",
    "Chew Poh Imm": "aseow6@gmail.com",
    "Ethan Lai": "daveckw@gmail.com",
    "Terence Wee Ming Han": "sendy1115@gmail.com",
    "Mohd Amir": "tansiangloong@gmail.com",
    "Ivan Lim": "daveckw@gmail.com",
    "Wayne Cheah Poh Wai": "aseow6@gmail.com",
    "Jasmine Loo": "daveckw@gmail.com",
    "Ez Ong Ee Zun": "seanteo.prop@gmail.com",
    "Zac Weng": "alanlaiproperty88@gmail.com",
    "Jensean Loo": "daveckw@gmail.com",
    "Jerry Foo": "daveckw@gmail.com",
    "Meagan Lim": "alanlaiproperty88@gmail.com",
    "Ou Yong Hua": "daveckw@gmail.com",
    "Kein Eng": "daveckw@gmail.com",
    "Eugene Khoo": "mjzie.my@gmail.com",
    "Kelvin Chan": "seanteo.prop@gmail.com",
    "Chris Tan": "ethanecg@gmail.com",
    "Lee Jeannie": "sashalian.88@gmail.com",
    "Rex Ong Ee How": "daveckw@gmail.com",
    "Tony Tham Tong Keen": "limyokehar@yahoo.com",
    "Scarlett Chong Jia Ern": "kohpohfen@gmail.com",
    "Martin Soh": "propertyeddie88@gmail.com",
    "Gavin Chai": "joyceyeohedge@gmail.com",
    "Wan Ahmad Zikri": "daveckw@gmail.com",
    "Jc Shik": "freddiechan95@gmail.com",
    "Calvin Lo Boon Hui": "joanlimce@gmail.com"
};
