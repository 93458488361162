import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadUsersRT(user, setUsers, setUnsubscribeUsers, setLoading) {
    try {
        setLoading(true);
        const collectionRef = collection(db, "users");
        const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
            console.log("Updating users...");
            const users = mapSnapshot(snapshot);
            setUsers(users);
            setLoading(false);
        });
        setUnsubscribeUsers(() => () => unsubscribe());
    } catch (error) {
        console.log(error);
    }
}
