import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function updateProjects(projects) {
    projects.forEach(async (project) => {
        if (project.updated) {
            const docRef = doc(db, "projects", project.name);
            await setDoc(
                docRef,
                { ...project, commRate: project.commRate, name: project.name, updatedAt: new Date() },
                { merge: true }
            );
            console.log(project.name, " updated in DB");
        }
    });
}
