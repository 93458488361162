import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function populateInvolveInSelectedUser(
    users,
    selectedUsers
) {
    try {
        selectedUsers.forEach(async (user) => {
            let involve = [];
            let involveId = [];
            const findLeader = (agentName) => {
                const user = users.find((doc) => doc.agent === agentName);
                if (user) {
                    involve.push(user.agent);
                    involveId.push(user.id);
                    findLeader(user.directLeader);
                } else {
                    return;
                }
            };
            findLeader(user.agent);
            const docRef = doc(db, "users", user.id);
            const newUser = {
                ...user,
                involve: involve,
                involveId: involveId
            };
            await setDoc(docRef, newUser, { merge: true });
            console.log(newUser);
            console.log(user.id, " updated");
        });
    } catch (error) {
        console.log(error);
    }
}
