import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import UploadExcel from "../components/UploadExcel";
import { AuthContext } from "../context/AuthContext";
import { UsersContext } from "../context/UsersContext";
import loadUsers from "../functions/loadUsers";
import seedIncomes from "../seeding-functions/seedIncomes";
import {
    GridContainer,
    GridDivider,
    GridFlexBox,
    Loading,
    Title
} from "../themes/themes";

export default function SeedIncomes() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const { users, setUsers } = useContext(UsersContext);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const getUsers = async () => {
            setLoading(true);
            const users = await loadUsers(user);
            setUsers(users);
            setLoading(false);
        };
        if (!users) getUsers();
    }, []);

    const convertToObj = (data) => {
        console.log(data);
        setData(data);
    };

    const upload = () => {
        seedIncomes(data, users);
    };

    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>Seed Incomes</Title>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridDivider />
                <GridFlexBox>
                    <UploadExcel
                        upload={convertToObj}
                        customHeader={["agent", "amount", "email"]}
                    />
                </GridFlexBox>
                <GridFlexBox fs>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ margin: 1 }}
                        onClick={upload}
                    >
                        Upload
                    </Button>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
