import { Container, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SaleDetails from "../components/SaleDetails";
import SalesTopBar from "../components/SalesTopBar";
import { AuthContext } from "../context/AuthContext";
import { SalesContext } from "../context/SalesContext";
import convertToLowerCase from "../utils-functions/convertToLowerCase";
import loadSales from "../functions/loadSales";
import { GridContainer, GridDivider } from "../themes/themes";

const numberPerPage = 50;

export default function SalesPage() {
    const { sales, setSales } = useContext(SalesContext);
    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState("all");
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const sales = await loadSales(user);
            setSales(sales);
            setLoading(false);
        };
        if (!sales) getData();
    }, []);

    useEffect(() => {
        const salesPageMemory = JSON.parse(
            localStorage.getItem("salesPageMemory")
        );
        if (salesPageMemory) {
            const { tabValue, search, page, windowScrollY } = salesPageMemory;
            const updateState = async () => {
                setTabValue(tabValue);
                setSearch(search);
                setPage(page);
                setTimeout(() => {
                    window.scroll(0, windowScrollY);
                }, 0.1);
            };
            updateState();
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const saveSettings = (settings) => {
        const windowScrollY = window.scrollY;
        let salesPageMemory = { tabValue, search, page, windowScrollY };
        salesPageMemory = { ...salesPageMemory, ...settings };
        localStorage.setItem(
            "salesPageMemory",
            JSON.stringify(salesPageMemory)
        );
    };

    const handleOpen = async (sale) => {
        saveSettings({});
        console.log(sale);
        navigate(`/sale-card-page/${sale.id}`);
    };

    const handleSearch = (value) => {
        setSearch(value);
        setPage(1);
        window.scrollTo(0, 0);
        saveSettings({ search: value, page: 1 });
    };

    const handleSetTabValue = (value) => {
        setTabValue(value);
        setPage(1);
        window.scrollTo(0, 0);
    };

    let filteredSales = sales;

    let totalPage = 1;
    if (filteredSales) {
        switch (tabValue) {
            case "all":
                break;
            default:
                filteredSales = sales.filter((sale) => sale.type === tabValue);
        }
        filteredSales = filteredSales.filter((sale) => {
            const { agent, project, unitNo, buyerName, branch } = sale;
            const searchField =
                convertToLowerCase(agent) +
                convertToLowerCase(project) +
                convertToLowerCase(unitNo) +
                convertToLowerCase(buyerName) +
                convertToLowerCase(branch);
            return searchField.includes(search.toLowerCase());
        });
        totalPage = Math.ceil(filteredSales.length / numberPerPage);
        if (totalPage === 0) totalPage = 1;
    }

    let unitIdArray = [];

    return (
        <Box m={1}>
            <Container component="main" maxWidth="sm" disableGutters>
                <SalesTopBar
                    setSearch={setSearch}
                    handleSetTabValue={handleSetTabValue}
                    tabValue={tabValue}
                    page={page}
                    setPage={setPage}
                    totalPage={totalPage}
                    search={search}
                    handleSearch={handleSearch}
                    totalItem={filteredSales ? filteredSales.length : 0}
                />
                <GridContainer>
                    {filteredSales &&
                        filteredSales
                            .slice(
                                page * numberPerPage - numberPerPage,
                                page * numberPerPage
                            )
                            .map((sale, i) => {
                                let existed = checkSaleId(
                                    unitIdArray,
                                    sale.unitId
                                );
                                unitIdArray.push(sale.unitId);
                                return (
                                    <SaleDetails
                                        existed={existed}
                                        handleOpen={handleOpen}
                                        sale={sale}
                                        handleClickFace={handleSearch}
                                        key={i}
                                    />
                                );
                            })}
                    <GridDivider ptb={10} />
                </GridContainer>
                {loading && <LinearProgress />}
            </Container>
        </Box>
    );
}

const checkSaleId = (unitIdArray, unitId) => {
    if (
        unitIdArray.includes(unitId) &&
        unitIdArray[unitIdArray.length - 1] === unitId
    ) {
        return true;
    } else return false;
};
