import React from "react";
import FullDialog from "../FullDialog";
import { useState } from "react";
import { Button, Chip, Container, TextField } from "@mui/material";
import { GridContainer, GridFlexBox, Name } from "../../themes/themes";
import { useEffect } from "react";
import saveNote from "./saveNote";
import toast from "react-hot-toast";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import deleteNote from "./deleteNote";
import { ConfirmationContext } from "../../context-utils/ConfirmationContext";
import chatGPT from "../../pages/Playground/chatGPT";

export default function SaveNoteComponent({ note, handleClose, updateDelete }) {
    const [open, setOpen] = useState(false);
    const { user } = useContext(AuthContext);

    const SaveNote = () => {
        const [noteId, setNoteId] = useState(null);
        const [title, setTitle] = useState("");
        const [desription, setDescription] = useState("");
        const [label, setLabel] = useState("");
        const [labels, setLabels] = useState([]);
        const [markdown, setMarkdown] = useState("");
        const [group, setGroup] = useState("");
        const [groups, setGroups] = useState([]);

        const { openConfirmation } = useContext(ConfirmationContext);

        useEffect(() => {
            if (note) {
                setMarkdown(note.markdown || "");
                setNoteId(note.id || "");
                setTitle(note.title || "");
                setDescription(note.desription || "");
                setLabels(note.labels || []);
                setGroups(note.groups || []);
            }
        }, []);

        const handleEnterLabel = (e) => {
            if (e.key === "Enter") {
                setLabels([...labels, label]);
                setLabel("");
            }
        };

        const handleAddLabel = () => {
            setLabels([...labels, label]);
            setLabel("");
        };

        const handleSave = async () => {
            if (!title) {
                toast.error("Title is required");
                return;
            }
            let newNote = {
                ...note,
                id: noteId,
                title,
                desription,
                labels,
                markdown,
                userId: user.id,
                groups
            };
            const response = await saveNote(newNote);
            if (response) {
                toast.success("Note saved successfully");
                newNote = { ...newNote, id: response };
            } else {
                toast.error("Error saving note");
            }
            setOpen(false);
            handleClose(newNote);
        };

        const handleDelete = (index) => {
            let newLabels = [...labels];
            newLabels.splice(index, 1);
            setLabels(newLabels);
        };

        const handleEnterGroup = (e) => {
            if (e.key === "Enter") {
                setGroups([...groups, group]);
                setGroup("");
            }
        };

        const handleAddGroup = () => {
            setGroups([...groups, group]);
            setGroup("");
        };

        const handleDeleteGroup = (index) => {
            let newGroups = [...groups];
            newGroups.splice(index, 1);
            setGroups(newGroups);
        };

        const handleDeleteNote = async () => {
            const response = await openConfirmation("Delete note", "Confirm delete note?");
            if (response) {
                await deleteNote(note);
                toast.success("Note deleted successfully");
                updateDelete(note);
                setOpen(false);
            }
        };

        const handleGenerateDescription = async () => {
            await chatGPT(
                markdown + " \nGenerate a concise summary of above not more than 40 words for indexing",
                null,
                [],
                "gpt-3.5-turbo",
                setDescription
            );
        };

        return (
            <Container maxWidth="md">
                <GridContainer>
                    <GridFlexBox sx={{ margin: "8px" }}>
                        <TextField
                            fullWidth
                            placeholder="Markdown..."
                            label="Markdown Text"
                            value={markdown}
                            onChange={(e) => setMarkdown(e.target.value)}
                            autoComplete="off"
                            required
                            multiline
                            rows={7}
                        />
                    </GridFlexBox>
                    <GridFlexBox sx={{ margin: "8px" }}>
                        <TextField
                            fullWidth
                            placeholder="Title..."
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            autoComplete="off"
                            required
                        />
                    </GridFlexBox>
                    <GridFlexBox sx={{ margin: "8px" }} xs={8}>
                        <TextField
                            fullWidth
                            placeholder="Description..."
                            label="Description"
                            value={desription}
                            onChange={(e) => setDescription(e.target.value)}
                            autoComplete="off"
                            multiline
                            rows={5}
                        />
                    </GridFlexBox>
                    <GridFlexBox sx={{ margin: "8px" }} xs={4} fs>
                        <Button variant="contained" onClick={() => handleGenerateDescription()}>
                            AI Generate
                        </Button>
                    </GridFlexBox>
                    <GridFlexBox fs sx={{ margin: "8px" }}>
                        <Name>Labels: </Name>
                        {labels.map((label, index) => (
                            <React.Fragment key={index}>
                                <Chip label={label} onDelete={() => handleDelete(index)} />
                            </React.Fragment>
                        ))}
                    </GridFlexBox>
                    <GridFlexBox sx={{ margin: "8px" }} xs={8}>
                        <TextField
                            fullWidth
                            placeholder="Label..."
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                            onKeyDown={handleEnterLabel}
                        />
                    </GridFlexBox>
                    <GridFlexBox fs sx={{ margin: "8px" }} xs={4}>
                        <Button variant="contained" onClick={() => handleAddLabel()}>
                            Add Label
                        </Button>
                    </GridFlexBox>
                    <GridFlexBox fs sx={{ margin: "8px" }}>
                        <Name>Groups: </Name>
                        {groups.map((group, index) => (
                            <React.Fragment key={index}>
                                <Chip label={group} onDelete={() => handleDeleteGroup(index)} />
                            </React.Fragment>
                        ))}
                    </GridFlexBox>
                    <GridFlexBox fs sx={{ margin: "8px" }} xs={8}>
                        <TextField
                            fullWidth
                            placeholder="Group..."
                            value={group}
                            onChange={(e) => setGroup(e.target.value.toLowerCase())}
                            onKeyDown={handleEnterGroup}
                        />
                    </GridFlexBox>
                    <GridFlexBox fs sx={{ margin: "8px" }} xs={4}>
                        <Button variant="contained" onClick={() => handleAddGroup()}>
                            Add Group
                        </Button>
                    </GridFlexBox>
                    <GridFlexBox fs sx={{ margin: "8px" }}>
                        <Button variant="contained" type="submit" onClick={handleSave}>
                            {noteId ? "Update" : "Save"}
                        </Button>
                        <Button onClick={() => setOpen(false)} variant={"contained"} color="warning">
                            Close
                        </Button>
                    </GridFlexBox>
                    {noteId && (
                        <GridFlexBox fs>
                            <Button color={"error"} onClick={handleDeleteNote}>
                                delete
                            </Button>
                        </GridFlexBox>
                    )}
                </GridContainer>
            </Container>
        );
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} variant={"contained"}>
                {note.id ? "Update" : "Save"}
            </Button>

            <FullDialog
                open={open}
                handleClose={() => setOpen(false)}
                title={"Save Note"}
                Component={<SaveNote handleClose={handleClose} />}
            />
        </>
    );
}
