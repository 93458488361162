import { blue, orange } from "@mui/material/colors";
import { styled } from "@mui/system";

const Status = styled("button")((props) => {
    let { clicked, bgcolor = orange[700] } = props;
    if (!clicked) bgcolor = "lightgray";
    return {
        border: "solid 1px",
        borderRadius: "7px",
        color: "white",
        borderColor: bgcolor,
        backgroundColor: bgcolor,
        fontSize: "12px",
        paddingLeft: "10px",
        paddingRight: "10px",
        cursor: "pointer"
    };
});

export default function StatusComponent({
    children,
    status,
    setStatus,
    bgColor = blue[700]
}) {
    const checkStatus = (value) => {
        switch (value) {
            case "Booked":
                if (status.includes("BOOKED")) return "true";
                break;
            case "Converted":
                if (status.includes("BILLED") || status.includes("TO-INVOICE"))
                    return "true";
                break;
            case "Cancelled":
                if (status.includes("CANCELLED")) return "true";
                break;
            default:
                return;
        }
    };

    const handleClickStatus = (value) => {
        console.log(value);
        switch (value) {
            case "Booked":
                updateState(["BOOKED"], setStatus, status);
                break;
            case "Converted":
                updateState(["BILLED", "TO-INVOICE"], setStatus, status);
                break;
            case "Cancelled":
                updateState(["CANCELLED"], setStatus, status);
                break;
            default:
                return;
        }
    };

    function updateState(s, setStatusFunction, state) {
        let newState = [...state];

        s.forEach((a) => {
            if (state.includes(a)) {
                let index = newState.findIndex((b) => b === a);
                newState.splice(index, 1);
            } else {
                newState = [...newState, a];
            }
        });

        setStatusFunction(newState);
    }

    return (
        <Status
            bgcolor={bgColor}
            clicked={checkStatus(children)}
            onClick={() => handleClickStatus(children)}
        >
            {children}
        </Status>
    );
}
