import { Icon, InputAdornment, styled, TextField } from "@mui/material";

const SearchInput = styled(TextField)({
    font: "Roboto"
});

export default function SearchComponent({ handleSearch, search }) {
    const handleChange = (e) => {
        const { value } = e.target;
        handleSearch(value);
    };

    const clickClear = () => {
        handleSearch("");
    };

    return (
        <SearchInput
            size="small"
            value={search}
            onChange={handleChange}
            type="text"
            placeholder={"Search..."}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" onClick={clickClear}>
                        <Icon
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            clear
                        </Icon>
                    </InputAdornment>
                )
            }}
        />
    );
}
