import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import getDayMonthYear from "../utils-functions/getDayMonthYear";
import { notification } from "../utils-functions/notification";

export default async function loadSales(
    user,
    year = parseInt(getDayMonthYear(new Date()).year),
    lastYear = parseInt(getDayMonthYear(new Date()).year) - 1,
    yearsLoaded = []
) {
    if (yearsLoaded.includes(year)) {
        console.log(year, " already loaded");
        return;
    }
    
    try {
        const salesRef = collection(db, "sales");

        let salesSnap = [];

        if (user.role === "Super Admin" || user.role === "Admin") {
            console.log(lastYear);
            console.log(year);
            const q = query(
                salesRef,
                where("bookingDate", ">=", new Date(`${lastYear}-01-01`)),
                where("bookingDate", "<=", new Date(`${year}-12-31`)),
                orderBy("bookingDate", "desc")
            );
            salesSnap = await getDocs(q);
        } else {
            const q = query(
                salesRef,
                where("involveId", "array-contains", user.id),
                where("bookingDate", ">=", new Date(`${lastYear}-01-01`)),
                where("bookingDate", "<=", new Date(`${year}-12-31`)),
                orderBy("bookingDate", "desc")
            );
            salesSnap = await getDocs(q);
        }

        if (!salesSnap.empty) {
            const sales = salesSnap.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id
            }));
            console.log(sales);
            return sales;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err);
        notification("Error", err.message, "danger");
    }
}
