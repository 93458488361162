import convertDate from "../utils-functions/convertDate";

export default async function getGroupSales(
    sales,
    user,
    startDate,
    endDate,
    type = ["project"],
    status = ["BOOKED", "BILLED", "TO-INVOICE"]
) {
    let groupSales = {};
    let personalSales = {};
    if (!sales) return null;
    if (!user) return null;

    let filteredSales = sales.filter((sale) => sale.involveId.includes(user.id));

    filteredSales.forEach((sale) => {
        sale.involve.every((userName) => {
            if (
                convertDate(sale.bookingDate) >= startDate &&
                convertDate(sale.bookingDate) <= endDate &&
                checkType(sale.type, type) &&
                checkStatus(sale, status)
            ) {
                pushToObject(groupSales, userName, sale);
                if (sale.agent === userName) pushToObject(personalSales, userName, sale);

                //skip uplines
                if (userName === user.agent) return false;
            }
            return true;
        });
    });

    let groupSalesArray = [];
    Object.keys(groupSales).forEach((agent) => {
        groupSalesArray.push({
            name: agent,
            groupValue: groupSales[agent],
            personalValue: personalSales[agent] ? personalSales[agent] : 0
        });
    });
    groupSalesArray.sort((a, b) => {
        return b.groupValue - a.groupValue;
    });

    return groupSalesArray;
}

const checkStatus = (sale, status) => {
    if (status.includes(sale.status)) {
        return true;
    } else return false;
};

function pushToObject(groupSales, userName, sale) {
    groupSales[userName] = groupSales[userName]
        ? groupSales[userName] + parseFloat(sale.netPricePortion)
        : parseFloat(sale.netPricePortion);
}

function checkType(saleType, type) {
    if (type.includes(saleType)) {
        return true;
    } else return false;
}
