import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import { useState } from "react";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import { GridDivider, GridFlexBox } from "../themes/themes";
import PaginationComponent from "./PaginationComponent";
import SearchComponent from "./SearchComponent";

export default function GlobalList({
    onClose,
    selectedValue,
    open,
    objects,
    displayName,
    title,
    icon,
    addAccount,
    search
}) {
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(1);

    const handleClose = () => {
        onClose(selectedValue);
        setSearchValue("");
    };

    const handleListItemClick = (value) => {
        onClose(value);
        setSearchValue("");
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        setPage(1);
    };

    let filteredObjects = objects;
    let totalPage = 1;
    let start = 0;
    let end = 1;
    if (filteredObjects) {
        filteredObjects = objects.filter((object) => {
            if (object[displayName]) {
                if (
                    object[displayName]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        });
        totalPage = Math.ceil(filteredObjects.length / 50);
        start = 50 * page - 50;
        end = 50 * page;
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle id="simple-dialog-title" style={{ padding: "10px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <IconButton size="small" onClick={handleClose}>
                                <Icon>clear</Icon>
                            </IconButton>
                            {title}
                        </Box>
                    </Grid>
                    <GridFlexBox fs p5>
                        {search && (
                            <SearchComponent
                                search={searchValue}
                                handleSearch={handleSearch}
                            />
                        )}
                    </GridFlexBox>
                    <GridFlexBox fs p5>
                        <PaginationComponent
                            page={page}
                            setPage={setPage}
                            totalPage={totalPage}
                        />
                    </GridFlexBox>
                    <GridDivider ptb={4} />
                </Grid>
            </DialogTitle>
            <div
                style={{
                    overflowX: "hidden",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <List style={{ minWidth: "350px", height: "600px" }}>
                    {!arrayIsEmpty(objects) &&
                        filteredObjects.slice(start, end).map((object, i) => (
                            <ListItem
                                button
                                onClick={() => handleListItemClick(object)}
                                key={i}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <Icon>{icon}</Icon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={object[displayName]} />
                            </ListItem>
                        ))}
                    {addAccount && (
                        <ListItem
                            button
                            onClick={() => handleListItemClick("addAccount")}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <Icon>add</Icon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Add account" />
                        </ListItem>
                    )}
                </List>
            </div>
        </Dialog>
    );
}
