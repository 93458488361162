export const findUserInAtlas = async (name) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append(
        "Accept-Language",
        "en-US,en;q=0.9,zh-CN;q=0.8,zh-TW;q=0.7,zh;q=0.6"
    );
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append(
        "Authorization",
        "JWT eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoyMTAsImlhdCI6MTY2MDc5ODAwOSwiaXNzIjoiQXRsYXMgQXBpIiwiZXhwIjoxNjYyMDA3NjA5fQ.4Tf_14mAblYCw0r6dQiNHlNqka-wjaFXCD_JQqo5UAz1-_fvCdLMFbEuTSjWC_mW_iLC_pL2nDw_NO8UONS1bg"
    );
    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Origin", "https://atlas.iqiglobal.com");
    myHeaders.append("Referer", "https://atlas.iqiglobal.com/");
    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-site");
    myHeaders.append(
        "User-Agent",
        "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
    );
    myHeaders.append(
        "sec-ch-ua",
        '"Chromium";v="104", " Not A;Brand";v="99", "Google Chrome";v="104"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Windows"');

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(
            `https://api.iqiglobal.com/api/v1/team_members?q[name_cont]=${name}&page=1`,
            requestOptions
        );
        const response2 = await response.json();
        console.log(response2.data[0]);
        return response2.data[0];
    } catch (error) {
        console.log(error);
    }
};
