import { Button, Grid, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { SalesContext } from "../context/SalesContext";
import { UsersContext } from "../context/UsersContext";
import loadSales from "../functions/loadSales";
import loadUsers from "../functions/loadUsers";
import populateInvolveInSales from "../seeding-functions/populateInvolveInSales";
import populateInvolveInUsers from "../seeding-functions/populateInvolveInUsers";
import findMissingUpline from "../test-functions/findMissingUpline";
import findMissingUplineInSales from "../test-functions/findMissingUplineInSales";
import { findUserInAtlas } from "../test-functions/findUserInAtlas";
import fixGary from "../test-functions/fixGary";
import fixHan from "../test-functions/fixHan";
import populateInvolves from "../test-functions/populateInvolves";

const MyButton = styled(Button)({
    margin: 5
});

export default function TestPage() {
    const { users, setUsers } = useContext(UsersContext);
    const { sales, setSales } = useContext(SalesContext);
    const { user } = useContext(AuthContext);
    useEffect(() => {
        const getUsers = async () => {
            const users = await loadUsers(user);
            setUsers(users);
        };
        const getSales = async () => {
            const sales = await loadSales(user);
            setSales(sales);
        };
        if (!users) getUsers();
        if (!sales) getSales();
    }, []);

    return (
        <Box m={1}>
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent={"center"}>
                        <Typography variant="h5">Test Page</Typography>
                    </Box>
                    <Box gap={2}>
                        <MyButton variant="contained" onClick={() => populateInvolveInSales(users, sales)}>
                            Populate Involve in Sales
                        </MyButton>
                        <MyButton variant="contained" onClick={() => populateInvolveInUsers(users, sales)}>
                            Populate Involve in Users
                        </MyButton>
                        <MyButton variant="contained" onClick={() => findMissingUpline(users)}>
                            Find Missing Upline
                        </MyButton>
                        <MyButton variant="contained" onClick={() => findMissingUplineInSales(sales)}>
                            Find Missing Upline in Sales
                        </MyButton>
                        <MyButton variant="contained" onClick={() => fixHan(sales, users)}>
                            Fix Han
                        </MyButton>
                        <MyButton variant="contained" onClick={() => findUserInAtlas("Jess Tan Pik Yoke")}>
                            Find User in Atlas
                        </MyButton>
                        <MyButton variant="contained" onClick={() => fixGary(users, sales)}>
                            Fix Gary involve
                        </MyButton>
                        <MyButton variant="contained" onClick={() => populateInvolves(users, sales)}>
                            Populate Involves in Sales
                        </MyButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
