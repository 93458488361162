import { Button, Grid, Paper, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SelectFile from "./SelectFileComponent";
import { ExcelRenderer } from "react-excel-renderer";
import React, { useState } from "react";
import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { notification } from "../utils-functions/notification";
import convertStringToCamelCase from "../utils-functions/convertStringToCamelCase";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import convertDate from "../utils-functions/convertDate";

const MyInput = styled("input")({
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "Roboto",
    paddingTop: 10,
    paddingBottom: 10
});

export default function UploadExcel({ upload = () => {}, customHeader = [] }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [headerIndex, setHeaderIndex] = useState(0);
    const [headerName, setHeaderName] = useState([]);

    useEffect(() => {
        console.log(rows);
        if (!arrayIsEmpty(rows)) {
            const headerName = [...Array(rows[0].length)].map((item, i) => {
                const camelCase = convertStringToCamelCase(rows[0][i]);
                return camelCase;
            });
            setHeaderName(headerName);
        }
    }, [rows, columns]);

    const handleClickUpload = (files) => {
        console.log(files);
        fileHandler(files);
    };

    const fileHandler = (files) => {
        let fileObj = files[0];
        console.log(files[0]);

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            const { rows, cols } = resp;
            if (err) {
                console.log(err);
            } else {
                const newRows = [];
                rows.forEach((row) => {
                    if (row[0]) {
                        newRows.push(row);
                    }
                });
                setRows(newRows);
                setColumns(cols);
            }
        });
    };

    const handleCheck = (i) => {
        setHeaderIndex(i);
    };

    const setHeader = () => {
        if (!headerIndex) {
            notification("No header", "Press check the header box", "danger");
        } else {
            const newRows = rows.slice(headerIndex, rows.length);
            setRows(newRows);
            console.log(newRows);
            setHeaderIndex();
        }
    };

    const handleChange = (e) => {
        const { value, id } = e.target;
        const newHeaderName = [...headerName];
        newHeaderName[id] = value;
        setHeaderName(newHeaderName);
    };

    const convertToObject = () => {
        if (arrayIsEmpty(rows)) {
            notification("Empty array", "There is no data to convert. Make sure you uploaded an Excel file", "warning");
            return;
        }

        const data = rows.slice(1, rows.length).map((row) => {
            let object = {};
            [...Array(row.length)].map((item, j) => {
                let cell = row[j];
                if (headerName[j].toLowerCase().includes("date")) {
                    cell = convertDate(cell);
                }
                object[headerName[j]] = cell ? cell : "";
            });
            return object;
        });
        upload(data);
    };

    const setCustomHeader = () => {
        if (customHeader.length === rows[0].length) {
            setHeaderName(customHeader);
            notification("Success", "Added Custom Header", "success");
        } else {
            notification("Error", "Custom Header length is the same as Column length", "danger");
        }
    };

    return (
        <Box m={1}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent="center">
                        <Typography variant="h5">Upload Excel</Typography>
                    </Box>
                    <Box display={"flex"} justifyContent="center" m={5}>
                        <SelectFile handleClickUpload={handleClickUpload} />
                    </Box>
                </Grid>
                <Paper style={{ overflow: "auto", width: "99vw", height: "40vh" }} variant="outlined">
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            {rows[0] &&
                                [...Array(rows[0].length)].map((item, j) => (
                                    <React.Fragment key={j}>
                                        <Box>{!j && <Checkbox disabled />}</Box>
                                        <Box
                                            sx={{
                                                width: "150px",
                                                borderBottom: "solid 1px"
                                            }}
                                            justifyContent={"left"}
                                            flexWrap={"noWrap"}
                                            flexShrink={0}
                                        >
                                            <MyInput
                                                id={j}
                                                size="small"
                                                value={arrayIsEmpty(headerName) ? "" : headerName[j]}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </React.Fragment>
                                ))}
                        </Box>
                        {rows
                            .slice(0, 30)
                            .concat(rows.slice(rows.length - 10, rows.length))
                            .map((row, i) => (
                                <Box display="flex" key={i} alignItems="center">
                                    {[...Array(row.length)].map((item, j) => (
                                        <React.Fragment key={j}>
                                            <Box>
                                                {!j && (
                                                    <Checkbox
                                                        checked={headerIndex === i ? true : false}
                                                        onChange={() => handleCheck(i)}
                                                    />
                                                )}
                                            </Box>
                                            <Box
                                                sx={{ width: "150px" }}
                                                justifyContent={"left"}
                                                flexWrap={"noWrap"}
                                                flexShrink={0}
                                            >
                                                <Typography fontSize="small" noWrap>
                                                    {row[j]}
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            ))}
                    </Grid>
                </Paper>

                <Grid item xs={12}>
                    <Box display={"flex"} alignItems="center" gap={2}>
                        <Button variant="contained" onClick={setHeader}>
                            Set Header
                        </Button>
                        <Button variant="contained" onClick={setCustomHeader} color={"info"}>
                            Set Custom Header
                        </Button>
                        <Button variant="contained" onClick={convertToObject} color={"warning"}>
                            Convert to Object
                        </Button>
                        <Typography variant="body2">Total number of row: {rows.length}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
