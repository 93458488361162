import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "./mapSnapshot";

export default async function loadIncomes(user, year) {
    try {
        const incomesRef = collection(db, "incomes");
        const q = query(
            incomesRef,
            where("involveId", "array-contains", user.id),
            where("year", "==", year),
            orderBy("amount", "desc")
        );
        const incomesSnap = await getDocs(q);
        const incomes = mapSnapshot(incomesSnap);
        return incomes;
    } catch (err) {
        console.log(err);
    }
}
