import { Button, Grid } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import UploadDocument from "../components/UploadDocument";
import LandingPage from "../pages/LandingPage";

export default function TestPrintPage() {
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <LandingPage ref={componentRef}/>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={handlePrint}>
                    Print 
                </Button>
            </Grid>
            <UploadDocument
                handleFile={(object) => console.log(object)}
                parcel={{ id: "123" }}
            />
        </Grid>
    )
}
