import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/mapSnapshot";

export default async function loadRecentSales() {
    try {
        const collectionRef = collection(db, "sales");
        const q = query(collectionRef, orderBy("bookingDate", "desc"), limit(200));
        const snapshot = await getDocs(q);
        const sales = mapSnapshot(snapshot);
        console.log(sales);
        return sales;
    } catch (error) {
        console.log(error);
    }
}
