import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function loadUser(email) {
    try {
        const userRef = doc(db, "users", email);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            const userInFs = { ...userSnap.data(), id: userSnap.id };
            return userInFs;
        } else {
            console.log("Cannot find user ", email);
            return null
        }
    } catch (err) {
        console.log(err);
    }
}
